import styled from "styled-components";

export const HomeButton = styled.div`
width: 138px;
height: 40px;
border-radius: 8px;
padding: 0px 16px 0px 16px;
background-color: #000000;
font: Inter;
font-weight: 400;
font-size: 16px;
color: #FFFFFF;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
`