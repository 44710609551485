import React, { useState, useEffect } from 'react';
import { serverUpdateExamScheduleStatus } from '../../services/serverApi';
import { ExamStatusType } from '../../types/exam.d';

interface CountdownProps {
  dateString: string;
  timeString: string;
  examScheduleId?: string;
  getExamScheduleData: () => void;
}

const Countdown: React.FC<CountdownProps> = ({ dateString, timeString, examScheduleId, getExamScheduleData }) => {
  const [timeLeft, setTimeLeft] = useState<string>('');
  const [hasEnded, setHasEnded] = useState<boolean>(false);

  const handleUpdateStatus = async () => {
    try {
      await serverUpdateExamScheduleStatus(String(examScheduleId), ExamStatusType?.Pending)
      getExamScheduleData()
    } catch (err) {
      console.log(err);
    }
  };

  const calculateTimeLeft = (dateString: string, timeString: string): { timeString: string, diff: number } => {
    const [year, month, day] = dateString.split('-').map(Number);
    const [hours, minutes] = timeString.split(':').map(Number);
    const targetDate = new Date(year, month - 1, day, hours, minutes, 0, 0);
    const now = new Date();
    const diff = targetDate.getTime() - now.getTime();

    if (diff < 0) {
      return { timeString: '0 days 00:00:00', diff };
    }

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hoursRemaining = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutesRemaining = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const secondsRemaining = Math.floor((diff % (1000 * 60)) / 1000);

    return {
      timeString: `${days} days ${hoursRemaining.toString().padStart(2, '0')}:${minutesRemaining.toString().padStart(2, '0')}:${secondsRemaining.toString().padStart(2, '0')}`,
      diff,
    };
  };

  useEffect(() => {
    const { timeString: initialTimeLeft, diff: initialDiff } = calculateTimeLeft(dateString, timeString);
    setTimeLeft(initialTimeLeft);

    if (initialDiff < 0 && !hasEnded) {
      handleUpdateStatus();
      setHasEnded(true);
      return;
    }

    if (initialDiff >= 0) {
      const timer = setInterval(() => {
        const { timeString: updatedTimeLeft, diff: updatedDiff } = calculateTimeLeft(dateString, timeString);
        setTimeLeft(updatedTimeLeft);

        if (updatedDiff < 0 && !hasEnded) {
          handleUpdateStatus();
          setHasEnded(true);
          clearInterval(timer);
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [dateString, timeString, hasEnded]);

  return (
    <div>
      <p>{timeLeft}</p>
    </div>
  );
};

export default Countdown;
