import styled from 'styled-components'

export const RegisterContainer = styled.div`
width: 100vw;
height: 100vh;
display: flex;
align-items: center;
`
export const RegisterLeftContainer = styled.div`
width: 60%;
height: 100%;
background: linear-gradient(#0575E6 100%, #02298A 100%, #021B79 100%);
display: flex;
align-items: center;
justify-content: center;
`
export const RegisterRightContainer = styled.div`
width: 40%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
`
export const RegisterFormContainer = styled.div`
width: 307px;
height: auto;
display: flex;
flex-direction: column;
/* justify-content: space-between; */
gap: 20px;
`
export const RegisterTitleContainer = styled.div`
width: 411px;
height: 216px;
display: flex;
flex-direction: column;
gap: 23px;
`
export const RegisterTitle = styled.div`
font: Poppins;
font-weight: 700;
font-size: 40px;
color: #FFFFFF;
white-space: pre-wrap;
word-wrap: break-word;
overflow-wrap: break-word;
`
export const RegisterSubTitle = styled.div`
font: Poppins;
font-weight: 500;
font-size: 18px;
color: #FFFFFF;
`
export const RegisterFormHeaderContainer = styled.div`
display: flex;
flex-direction: column;
gap: 5px;
`
export const RegisterFormHeaderTitle = styled.div`
font: Poppins;
font-weight: 700;
font-size: 26px;
color: #333333;
`
export const RegisterFormHeaderSubTitle = styled.div`
font: Poppins;
font-weight: 400;
font-size: 18px;
color: #333333;
`
export const RegisterFormBodyContainer = styled.div`
width: 100% !important;
display: flex;
flex-direction: column;
gap: 16px;
`
export const Form = styled.form`
width: 100% !important;
display: flex;
flex-direction: column;
gap: 16px;
`
export const LoginErrorLabel = styled.div`
background: #FF0000;
padding: 10px;
color: #FFFFFF;
border-radius: 4px;
`
export const ForgotPasswordLink = styled.a`
cursor: pointer;
color: #333333;
opacity: 0.7;
align-self: center;
`