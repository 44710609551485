// src/hooks/useToast.js
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useToast = () => {
  const showToast = (type: string, message: string, options = {}) => {
    switch (type) {
      case 'success':
        toast.success(message, options);
        break;
      case 'error':
        toast.error(message, options);
        break;
      case 'info':
        toast.info(message, options);
        break;
      case 'warn':
        toast.warn(message, options);
        break;
      default:
        toast(message, options);
        break;
    }
  };

  const ToastProvider = () => <ToastContainer />;

  return { showToast, ToastProvider };
};

export default useToast;
