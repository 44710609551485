import React, { useState } from 'react'

const useAppHeader = () => {
    const [openRequestDemoDialog, setOpenRequestDemoDialog] = useState<boolean>(false);

    const handleOpenRequestDemoDialog = () => {
        setOpenRequestDemoDialog(true);
    }

    const handleCloseRequestDemoDialog = () => {
        setOpenRequestDemoDialog(false);
    }

    return {
        openRequestDemoDialog,
        handleOpenRequestDemoDialog,
        handleCloseRequestDemoDialog
    }
}

export default useAppHeader