import React from 'react'
import { EmailContainer, EmailInputContainer, EmailLable, Form, Input, NameContainer, NameInputContainer, NameLable, NumberContainer, NumberInputContainer, NumberLable, ProfileContainer, ProfileInput, ProfilePicture, ProfilePictureContainer, ProfilePictureLable, ProfilePictureSelectInputContainer, ProfilePictureSelectInputWrapper, ProfilePictureWrapper, UpdateProfileButton, UploadImg, UploadLable } from './Profile.styled'
import { uploadIcon } from '../../assets/Profile/inde'
import useProfile from './useProfile'
import { Formik } from "formik";
import { isAnyFieldEmpty } from '../../utils/helpers/global';
import { CircularProgress } from '@mui/material';

const Profile = () => {
    const {
        fileInputRef,
        handleFileChange,
        handleSelectFile,
        adminData,
        handleInputChange,
        file,
        schema,
        handleSubmit,
        loading
    } = useProfile()

    return (
        <ProfileContainer>
            {/* <Formik
                validationSchema={schema}
                initialValues={{
                    firstName: adminData?.firstName || '',
                    middleName: adminData?.middleName || '',
                    lastName: adminData?.lastName || '',
                    email: adminData?.email || '',
                    number: adminData?.number || '',
                }}
                onSubmit={(values) => {
                    console.log('values', values);
                    // if (editData) {
                    // handleUpdate({...values, _id: editData?._id, imageSrc: imageSrc, deleteProfileImg: editData?.profileImg})
                    // } else {
                    // handleSubmit({...values, imageSrc: imageSrc})
                    // }
                }}
                >
                {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                }) => (
                    <Form onSubmit={handleSubmit}> */}
                        <ProfilePictureContainer>
                            <ProfilePictureLable>Profile Picture</ProfilePictureLable>
                            <ProfilePictureSelectInputWrapper>
                                <ProfilePictureSelectInputContainer onClick={handleSelectFile}>
                                    <UploadImg src={uploadIcon}></UploadImg>
                                    <UploadLable>Select your images here</UploadLable>
                                    <ProfileInput type='file' accept="image/*" onChange={handleFileChange} ref={fileInputRef}></ProfileInput>
                                </ProfilePictureSelectInputContainer>
                                <ProfilePictureWrapper>
                                    <ProfilePicture src={file ? URL.createObjectURL(file) : adminData?.profileImg} alt="Profile Picture"></ProfilePicture>
                                </ProfilePictureWrapper>
                            </ProfilePictureSelectInputWrapper>
                        </ProfilePictureContainer>
                        <NameContainer>
                            <NameLable>Name</NameLable>
                            <NameInputContainer>
                                <Input 
                                    type='text' 
                                    name='firstName' 
                                    onChange={(e) => {
                                        // handleChange(e);
                                        handleInputChange(e)
                                    }}
                                    value={adminData?.firstName} 
                                    placeholder='Enter Your FirstName'
                                />
                                <Input 
                                    type='text' 
                                    name='middleName' 
                                    onChange={(e) => {
                                        // handleChange(e);
                                        handleInputChange(e)
                                    }}
                                    value={adminData?.middleName} 
                                    placeholder='Enter Your MiddleName'
                                />
                                <Input 
                                    type='text' 
                                    name='lastName' 
                                    onChange={(e) => {
                                        // handleChange(e);
                                        handleInputChange(e)
                                    }}                                    
                                    value={adminData?.lastName} 
                                    placeholder='Enter Your LastName'
                                />
                            </NameInputContainer>
                        </NameContainer>
                        <EmailContainer>
                            <EmailLable>Email</EmailLable>
                            <EmailInputContainer>
                                <Input 
                                    type='email' 
                                    name='email' 
                                    onChange={(e) => {
                                        // handleChange(e);
                                        handleInputChange(e)
                                    }}                                    
                                    value={adminData?.email} 
                                    placeholder='Enter Your Email'
                                />
                            </EmailInputContainer>
                        </EmailContainer>
                        <NumberContainer>
                            <NumberLable>Number</NumberLable>
                            <NumberInputContainer>
                                <Input 
                                    type='number' 
                                    name='number' 
                                    onChange={(e) => {
                                        // handleChange(e);
                                        handleInputChange(e)
                                    }}                                    
                                    value={adminData?.number} 
                                    placeholder='Enter Your Number'
                                />
                            </NumberInputContainer>
                        </NumberContainer>
                        <UpdateProfileButton isDisabled={isAnyFieldEmpty(adminData ?? {})} onClick={() => handleSubmit(adminData)}>{loading ? <CircularProgress size={16} sx={{color: '#ffffff'}} /> : 'Update Profile'}</UpdateProfileButton>
                    {/* </Form>
                )}
            </Formik> */}
        </ProfileContainer>
    )
}

export default Profile