import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Main from './views/Main/Main';
import MainProfile from './views/MainProfile/MainProfile';

function App() {

  const [isActive, setIsActive] = useState(() => {
    const savedState = localStorage.getItem('isActive');
    return savedState !== null ? JSON.parse(savedState) : false;
  });

  // useEffect(() => {
  //   // Save the isActive state to localStorage whenever it changes
  //   localStorage.setItem('isActive', JSON.stringify(isActive));

  // }, [isActive]);

  // const handleToggle = () => {
  //   setIsActive((prevState: any) => !prevState);
  // };
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.tsx</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
    // <Main />
    // <MainProfile />
    <>
      {isActive ? <MainProfile /> : <Main />}
    </>
  );
}

export default App;
