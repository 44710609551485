import nameImg from './nameIcon.svg';
import emailImg from './emailIcon.svg';
import passwordImg from './passwordIcon.svg';
import passwordShowImg from './passwordShowIcon.svg';
import passwordHideImg from './passwordHideIcon.svg';

export const nameIcon = nameImg;
export const emailIcon = emailImg;
export const passwordIcon = passwordImg;
export const passwordShowIcon = passwordShowImg;
export const passwordHideIcon = passwordHideImg;