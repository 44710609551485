import styled from "styled-components";

export const StartExamButton = styled.div`
width: 91px;
height: 25px;
border-radius: 90px;
background-color: #3F87FE;
font: Inter;
font-weight: 400;
font-size: 12px;
color: #FFFFFF;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
`
export const Notice = styled.div`
font: Inter;
font-weight: 700;
font-weight: 14.53px;
color: #333333;
`
export const ExamStatus = styled.div<{bgColor: string, color: string}>`
padding: 5px 15px 5px 15px;
border-radius: 24px;
background-color: ${({ bgColor }) => bgColor};
color: ${({ color }) => color};
`