import styled from "styled-components";

export const AccountNameContainer = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 5px;
`
export const AccountName = styled.div`
font: Inter;
font-weight: 400;
font-size: 12px;
color: #000000;
`
export const AccountEmail = styled.div`
font: Inter;
font-weight: 400;
font-size: 12px;
color: #5B5B5B;
`
export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: white;
  border-bottom: 2px solid #e6e6e6;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  /* @media (max-width: 768px) {
    flex-direction: column;
  } */
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Logo = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;

  img {
    width: 100px;
    margin-right: 10px;
  }
`;

export const NavLinks = styled.nav`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 10px;
  }

  a {
    margin: 0 15px;
    text-decoration: none;
    color: #333;
    font-size: 1rem;

    @media (max-width: 768px) {
      margin: 5px 0;
    }

    &:hover {
      color: #007bff;
    }
  }
`;

export const AuthLinks = styled.div`
  display: flex;
  align-items: center;

  /* @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 10px;
  } */

  a {
    margin: 0 15px;
    text-decoration: none;
    color: #333;
    font-size: 1rem;

    @media (max-width: 768px) {
      margin: 5px 0;
    }

    &:hover {
      color: #007bff;
    }
  }
`;

export const FreeTrialButton = styled.div`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`