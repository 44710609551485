import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Webcam from 'react-webcam';
import { ICandidate } from '../../types/candidate';
import * as faceapi from 'face-api.js';
import { serverFetchImage } from '../../services/serverApi';
import { CandidateProfile, CaptureImg, ProfileMatchDialogContainer, ProfileMatchDialogWrapper } from './ProfileMatchDialog.styled';
import LoadingButton from '@mui/lab/LoadingButton';
import VerifiedIcon from '@mui/icons-material/Verified';
import CancelIcon from '@mui/icons-material/Cancel';

interface Props {
  open: boolean;
  handleClose: () => void;
  selectedExamData: any;
}

const ProfileMatchDialog: React.FC<Props> = ({ open, handleClose, selectedExamData }) => {
  const navigate = useNavigate();
  const handleNavigate = (data: any) => {
    navigate('/exam/active', { state: data });
  };

  const [adminData, setAdminData] = useState<ICandidate>();
  const webcamRef = useRef<Webcam>(null);
  const [matchResult, setMatchResult] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(true);
  const [capturedImage, setCapturedImage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [referenceImageUrl, setReferenceImageUrl] = useState<string | null>(null);

  useEffect(() => {
    const loadModels = async () => {
      try {
        const MODEL_URL = process.env.PUBLIC_URL + '/models';
        await faceapi.nets.ssdMobilenetv1.loadFromUri('https://evaluation.zerocodecourses.com/models/ssd_mobilenetv1_model-weights_manifest.json');
        await faceapi.nets.faceLandmark68Net.loadFromUri('https://evaluation.zerocodecourses.com/models/face_landmark_68_model-weights_manifest.json');
        await faceapi.nets.faceRecognitionNet.loadFromUri('https://evaluation.zerocodecourses.com/models/face_recognition_model-weights_manifest.json');
        setLoading(false);
      } catch (err) {
        console.error("Failed to load models:", err);
        setError("Failed to load models. Please check the model paths and try again.");
      }
    };
    loadModels();
  }, []);

  useEffect(() => {
    const adminDataString = localStorage.getItem('candidate');
    if (adminDataString) {
      try {
        const adminData = JSON.parse(adminDataString);
        setAdminData(adminData);
      } catch (error) {
        console.error("Error parsing admin data:", error);
      }
    } else {
      setAdminData(undefined);
    }
  }, []);

  const captureAndCompare = async () => {
    if (!webcamRef.current) return;
  
    const capturedImageBase64 = webcamRef.current.getScreenshot();
    if (!capturedImageBase64) {
      setError("Failed to capture image from webcam.");
      return;
    }

    setCapturedImage(capturedImageBase64);
  
    try {
      setLoading(true);
      if (!adminData?.profileImg) {
        setLoading(false);
        setCapturedImage(null);
        setError("Reference image URL is not set.");
        return;
      }
  
      // Fetch the reference image data
      const response = await serverFetchImage(encodeURIComponent(adminData.profileImg));
  
      // Ensure response handling based on serverFetchImage implementation
      const imageBuffer = response.imageBuffer; // Assuming this is the correct format
  
      // Create Blob from imageBuffer data
      const referenceImgBlob = new Blob([new Uint8Array(imageBuffer.data)], { type: 'image/jpeg' });
  
      // Convert Blob to URL for display purposes (optional)
      const referenceImgUrl = URL.createObjectURL(referenceImgBlob);
      setReferenceImageUrl(referenceImgUrl);
  
      // Convert base64 string to HTMLImageElement
      const capturedImg = new Image();
      capturedImg.src = capturedImageBase64;
  
      // Convert Blob to HTMLImageElement
      const referenceImg = new Image();
      referenceImg.src = referenceImgUrl;
  
      // Ensure images are loaded before processing
      await new Promise((resolve) => {
        capturedImg.onload = resolve;
      });
      await new Promise((resolve) => {
        referenceImg.onload = resolve;
      });
  
      // Detect faces in captured image
      const capturedDetections = await faceapi.detectAllFaces(capturedImg)
        .withFaceLandmarks()
        .withFaceDescriptors();
  
      // Detect faces in reference image
      const referenceDetections = await faceapi.detectAllFaces(referenceImg)
        .withFaceLandmarks()
        .withFaceDescriptors();
  
      if (capturedDetections.length === 0) {
        setError("No faces detected in the captured image.");
        setCapturedImage(null);
        setLoading(false);
        setMatchResult(false);
        return;
      }
  
      if (referenceDetections.length === 0) {
        setError("No faces detected in the reference image.");
        setCapturedImage(null);
        setLoading(false);
        setMatchResult(false);
        return;
      }
  
      // Create FaceMatcher with reference descriptors
      const faceMatcher = new faceapi.FaceMatcher(referenceDetections);
      console.log('faceMatcher', faceMatcher)
  
      // Find best match for captured face descriptor
      const bestMatch = faceMatcher.findBestMatch(capturedDetections[0].descriptor);

      console.log('bestMatch', bestMatch)
  
      // Set match result based on distance threshold
      if (bestMatch.distance > 0.6) {
        setError("Faces are not matching.");
        setCapturedImage(null);
        setLoading(false);
        setMatchResult(false);
        return;
      }
      setMatchResult(bestMatch.distance < 0.6); // Adjust threshold as needed
      setLoading(false);
    } catch (err) {
      setCapturedImage(null);
      setLoading(false);
      console.error("Error fetching images or detecting faces:", err);
      setError("Failed to fetch images or detect faces. Please try again.");
    }
  };
  
  
  
  console.log('adminData', adminData)

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title" sx={{ background: '#3F87FE', color: '#FFFFFF' }}>Profile Match</DialogTitle>
      <DialogContent dividers={true}>
        <ProfileMatchDialogContainer>
          <Chip
            sx={{alignSelf: 'flex-end'}}
            icon={matchResult === true ? <VerifiedIcon /> : <CancelIcon />}
            label={matchResult === true ? "Verified" : "Not Verified"}
            color={matchResult === true ? "success" : "error"}
          />
          <ProfileMatchDialogWrapper>
            {capturedImage ? (
              <CaptureImg src={capturedImage}></CaptureImg>
            ) : (
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width={240}
                height={240}
              />
            )}
            <CandidateProfile src={adminData?.profileImg}></CandidateProfile>
          </ProfileMatchDialogWrapper>
          <LoadingButton
            loading={loading}
            loadingPosition='center'
            variant='contained' 
            onClick={captureAndCompare} 
            disabled={matchResult ? true : false}
          >
            Capture and Compare
          </LoadingButton>
        </ProfileMatchDialogContainer>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} color='inherit'>Close</Button>
        <Button variant='contained' onClick={() => handleNavigate(selectedExamData)} color='primary' disabled={matchResult ? false : true}>Next</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProfileMatchDialog;