import styled from "styled-components";

export const AppButtonContainer = styled.button`
padding: 18px 26px 18px 26px;
gap: 10px;
border-radius: 30px;
background: #0575E6;
font: Poppins;
font-weight: 400;
font-size: 14px;
color: #FFFFFF;
border: none;
cursor: pointer;
width: 100%;
`