import styled from "styled-components";

// Wrapper around the entire input component
export const AppInputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

// Container for the input field and icons
export const AppInputContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 30px;
  border: 1px solid #DADADA;
  padding: 18px 26px;
  gap: 10px;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  opacity: 0.7;
`;

// Styling for the input field
export const StyledInput = styled.input`
  border: none;
  outline: none;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  opacity: 0.7;
  flex: 1;

  &::placeholder {
    color: #333333;
    opacity: 0.7;
  }
`;

// Styling for the icons
export const Icon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

// Container for the label and error message
export const InputLabelAndErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
`;