import styled from "styled-components";

export const AppReadMoreButtonContainer = styled.button`
width: 135px;
height: 37px;
border-radius: 30px;
padding: 8px, 30px, 8px, 30px;
gap: 10px;
background: #FFFFFF;
border: none;
font: Poppins;
font-weight: 400;
font-size: 14px;
color: #000000;
cursor: pointer;
`