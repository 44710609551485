import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Logout = () => {
    const navigate = useNavigate();
    const handleLogout = () => {
        localStorage.removeItem('isActive');
        navigate('/');
        window.location.reload();
        localStorage.removeItem('candidate')
        navigate('/Login')
    }
    useEffect(() => {
        handleLogout();
    }, [])
    return (
        <div>Logout</div>
    )
}

export default Logout