import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
import { Notice } from '../../views/ExamTable/ExamTable.styled';

interface Props {
  open: boolean;
  handleClose: () => void;
  handleOpenProfileMatchDialog: () => void;
}

const ExamNoticeDialog: React.FC<Props> = ({ open, handleClose, handleOpenProfileMatchDialog }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title" sx={{ background: '#FF0000', color: '#FFFFFF' }}>IMPORTANT NOTES!!</DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText
          id="scroll-dialog-description"
          tabIndex={-1}
        >
          <Notice>
          1. Please don't switch tab/window while the test is in progress
          </Notice>
          <Notice>2. Do not go Out of tab or open any other software Your</Notice>
          <Notice>3. you might risk terminating your test.</Notice>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} color='inherit'>Close</Button>
        <Button variant='contained' onClick={() => handleOpenProfileMatchDialog()} color='primary'>Next</Button>
      </DialogActions>
    </Dialog>

  )
}

export default ExamNoticeDialog