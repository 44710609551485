import styled from "styled-components";

export const ExamCompletedDialogContainer = styled.div`
display: flex;
flex-direction: column;
gap: 30px;
`
export const ExamCompletedDialogHeaderContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`
export const ExamCompletedDialogHeader = styled.div`
font: Inter;
font-weight: 700;
font-size: 32.75px;
color: #4CAF50;
`
export const ExamCompletedDialogDescription = styled.div`
font: Inter;
font-weight: 400;
font-size: 16px;
color: #000000;
`
export const ExamCompletedDialogBodyContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`
export const ExamCompletedDialogBodyTitle = styled.div`
font: Inter;
font-weight: 800;
font-size: 20px;
color: #000000;
`
export const ExamCompletedMarkContainer = styled.div`
display: flex;
align-items: center;
`
export const ExamCompletedPassingMark = styled.div`
font: Inter;
font-weight: 800;
font-size: 29px;
color: #4CAF50;
`
export const ExamCompletedTotalMark = styled.div`
font: Inter;
font-weight: 800;
font-size: 29px;
color: #000000;
`