import styled from "styled-components";

export const ProfileMatchDialogContainer = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
`
export const ProfileMatchDialogWrapper = styled.div`
display: flex;
align-items: center;
gap: 20px;
`
export const CandidateProfile = styled.img`
width: 240px;
height: 185px;
`
export const CaptureImg = styled.img`
width: 240px;
height: 185px;
`