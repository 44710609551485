import styled from "styled-components";

export const ContactContainer = styled.div`
width: 100%;
`
export const ContactWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 80px;
justify-content: center;
align-items: center;
padding: 100px 30px 100px 30px;
`
export const ContactInfoContainer = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
`
export const ContactInfoWrapper = styled.div`
display: flex;
align-items: center;
gap: 10px;
`
export const ContactInfoIconContainer = styled.div`
width: 80px;
height: 80px;
border-radius: 40px;
background-color: #0575E6;
display: flex;
align-items: center;
justify-content: center;
`
export const ContactInfoIcon = styled.img`
`
export const ContactDetailContainer = styled.div`
display: flex;
flex-direction: column;
gap: 5px;
`
export const ContactDetail = styled.div`
font: Urbanist;
font-weight: 600;
font-size: 24px;
color: #161B2D;
`
export const MessageContainer = styled.div`
max-width: 812px;
width: 100%;
height: 584px;
box-shadow: 5px 15px 45px 0px #161B2D0D;
background-color: #ffffff;
padding: 30px 50px 30px 50px;
`
export const MessageWrapper = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
gap: 100px;
justify-content: center;
`
export const MessageHeaderTitle = styled.div`
font: Urbanist;
font-weight: 700;
font-size: 44px;
color: #161B2D;
text-align: center;
`
export const MessageBodyContainer = styled.div`
`
export const Form = styled.form`
width: 100%;
display: flex;
flex-direction: column;
gap: 50px;
align-items: center;
`
export const MessageBodyRow = styled.div`
width: 100%;
display: flex;
align-items: center;
gap: 30px;
`
export const MapContainer = styled.div`
height: 549px;
width: 100%;
`