import styled from "styled-components";

export const AppFooterContainer = styled.div`
background: #1e1e1e;
height: auto;
/* width: 100%; */
padding-left: 60px;
padding-right: 60px;
padding-top: 60px;
padding-bottom: 20px;
display: flex;
flex-direction: column;
gap: 30px;
`
export const AppFooterLogoContainer = styled.div`
`
export const AppFooterLogo = styled.img`
`
export const AppFooterDescriptionContainer = styled.div`
max-width: 400px;
display: flex;
flex-direction: column;
gap: 30px;
`
export const AppFooterDescription = styled.div`
color: #ffffff;
font-size: 22px;
font-weight: bold;
`
export const AppFooterWrapper = styled.div`
display: flex;
align-items: flex-start;
justify-content: space-between;
gap: 20px;
padding-bottom: 30px;

@media screen and (max-width: 850px) {
  flex-direction: column;
}
`
export const GetStartButton = styled.button`
width: 160px;
height: auto;
background: #593bfe;
padding: 12px;
color: #ffffff;
border: none;
border-radius: 30px;
font-size: 18px;
`
export const AppFooterInformationContainer = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
`
export const ContactInfo = styled.div`
display: flex;
align-items: center;
gap: 20px;
`
export const ContactIcon = styled.img`
`
export const Contact = styled.div`
color: #ffffff;
`
export const MailInfo = styled.div`
display: flex;
align-items: center;
gap: 20px;
`
export const MailIcon = styled.img`
`
export const Mail = styled.div`
color: #ffffff;
`
export const DayTime = styled.div`
color: #ffffff;
`
export const SupportContainer = styled.div`
display: flex;
flex-direction: column;
gap: 5px;
`
export const PhoneSupport = styled.div`
color: #ffffff;
font-weight: bold;
`
export const EmailSupport = styled(PhoneSupport)`
`
export const Holiday = styled(PhoneSupport)`
`
export const FooterLinks = styled.div`
  /* list-style: none; */
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const FooterLink = styled.div`
  /* margin-bottom: 15px; */
  color: #888888;
  font-weight: 600;
  white-space: nowrap;

  &:hover {
    color: #ffffff;
  }
`;

export const Hr = styled.div`
width: 100%;
background: #888888;
background-color: #888888;
height: 1px;
`
export const ReservedContainer = styled.div`
`
export const Reserved = styled.div`
color: #ffffff;
`