import { AppBar, Box, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography } from '@mui/material';
import React, { Suspense, useEffect } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import SchoolIcon from '@mui/icons-material/School';
import VpnKeyOffIcon from '@mui/icons-material/VpnKeyOff';
import LogoutIcon from '@mui/icons-material/Logout';
import { HomeButton } from './MainProfile.styled';
import ExamTable from '../ExamTable/ExamTable';
import ExamModule from '../ExamModule/ExamModule';
import Profile from '../Profile/Profile';
import ChangePassword from '../ChangePassword/ChangePassword';
import useToast from '../../hooks/useToast'; // Import the custom hook
import Student from '../Student';
import ProfileDashboard from '../ProfileDashboard/ProfileDashboard';
import Logout from '../Logout/Logout';

const drawerWidth = 240;

interface Props {
  window?: () => Window;
}

const MainProfile: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  const { showToast, ToastProvider } = useToast(); // Destructure the hook

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleNavigate = () => {
    localStorage.removeItem('isActive');
    navigate('/');
    window.location.reload();
  }

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  useEffect(() => {
    if (location.pathname === '/' || location.pathname === '/Login') {
      navigate('/profile');
    }
  }, []);

  const MainProfileRouteData = [
    {
      title: 'Profile',
      path: '/profile',
      icon: <PersonIcon />,
    },
    {
      title: 'Exams',
      path: '/exams',
      icon: <SchoolIcon />,
    },
    {
      title: 'Change Password',
      path: '/change-password',
      icon: <VpnKeyOffIcon />,
    },
    {
      title: 'Logout',
      path: '/logout',
      icon: <LogoutIcon />,
    }
  ];

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {MainProfileRouteData.map((data, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton component={Link} to={data?.path} sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              {data?.icon}
              <ListItemText primary={data?.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const getRoutes = () => {
    return (
      <Suspense>
        <Routes location={location}>
          {/* <Route path="*" element={<Navigate to={'/profile'} />} /> */}
          <Route path="/exams" element={<Suspense><ExamTable /></Suspense>} />
          <Route path="/exam/active" element={<Suspense><ExamModule /></Suspense>} />
          <Route path="/profile" element={<Suspense><ProfileDashboard /></Suspense>} />
          <Route path="/edit-profile" element={<Suspense><Profile /></Suspense>} />
          <Route path="/change-password" element={<Suspense><ChangePassword /></Suspense>} />
          <Route path="/logout" element={<Suspense><Logout /></Suspense>} />
        </Routes>
      </Suspense>
    );
  };

  const getWindowDocumentBody = () => {
    return props.window ? props.window().document.body : undefined;
  };

  const container = getWindowDocumentBody();

  const routeData = MainProfileRouteData?.find((item) => item?.path === location?.pathname);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          background: '#ffffff'
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon sx={{ color: 'InfoText' }} />
          </IconButton>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Typography variant="h6" noWrap component="div" color={'InfoText'}>
              {routeData?.title}
            </Typography>
            <HomeButton onClick={() => handleNavigate()}>Back to Home</HomeButton>
          </div>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '60px', background: '100%' }}>
          {getRoutes()}
        </div>
        <ToastProvider /> {/* Add ToastProvider here */}
      </Box>
    </Box>
  );
}

export default MainProfile;