import React, { useEffect, useState } from 'react'
import { ContactContainer, ContactDetail, ContactDetailContainer, ContactInfoContainer, ContactInfoIcon, ContactInfoIconContainer, ContactInfoWrapper, ContactWrapper, Form, MapContainer, MessageBodyContainer, MessageBodyRow, MessageContainer, MessageHeaderTitle, MessageWrapper } from './Contact.styled'
import AppNavigation from '../../components/AppNavigation/AppNavigation'
import backgroundImage from '../../assets/AppNavigation/NavigationBackground.png';
import { CallIcon, EmailIcon, LocationIcon } from '../../assets/Contact';
import AppInput from '../../components/AppInput/AppInput';
import { Formik } from "formik";
import * as Yup from "yup";
import AppButton from '../../components/AppButton/AppButton';
import GoogleMapReact from 'google-map-react';
import { serverSentDemoRequest } from '../../services/serverApi';
import useToast from '../../hooks/useToast';

const Contact = () => {
    const { showToast } = useToast(); // Destructure the hook
    const [showSuccessLable, setShowSuccessLable] = useState<boolean>(false);
    const [loading, setLoading] = React.useState(false);

    const contactSchema = Yup.object().shape({
        name: Yup.string().required("Please enter a name.*"),
        subject: Yup.string().required("Please enter a subject.*"),
        email: Yup.string().email("Please enter a valid email address.*").required("Please enter an email.*"),
        message: Yup.string().required("Please enter a message.*"),
    });

    const defaultProps = {
        center: {
          lat: 10.99835602,
          lng: 77.01502627
        },
        zoom: 11
    };

    const handleRequestSubmit = async (data: any, actions: any) => {
        try {
            setLoading(true);
            await serverSentDemoRequest({...data, isDemoRequest: false});
            actions.resetForm();
            showToast('success', 'Message sent successfully!');
            setLoading(false);
        } catch (err) {
            showToast('error', 'Something went wrong. Please try again later.');
            setLoading(false);
            console.log(err);
        }
    };

    const AnyReactComponent = ({ text }: any) => <div>{text}</div>;

    useEffect(() => {
        setShowSuccessLable(false);
    }, []);

    return (
        <ContactContainer>
            <AppNavigation bgImage={backgroundImage} title={'Contact'} />
            <ContactWrapper>
                <MessageContainer>
                    <MessageWrapper>
                        <MessageHeaderTitle>Send Us Message</MessageHeaderTitle>
                        <MessageBodyContainer>
                            <Formik
                                validationSchema={contactSchema}
                                initialValues={{
                                name: '',
                                email: '',
                                subject: '',
                                message: ''
                                }}
                                onSubmit={handleRequestSubmit}
                            >
                                {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                }) => (
                                <Form onSubmit={handleSubmit}>
                                    <MessageBodyRow>
                                        <AppInput 
                                            placeholder='Name' 
                                            name={'name'} 
                                            handleChange={handleChange} 
                                            type={'text'} 
                                            handleBlur={handleBlur}
                                            errors={errors}
                                            touched={touched}
                                            value={values?.name}
                                        />

                                        <AppInput 
                                            placeholder='Email Address' 
                                            name={'email'} 
                                            handleChange={handleChange} 
                                            type={'text'} 
                                            handleBlur={handleBlur}
                                            errors={errors}
                                            touched={touched}
                                            value={values?.email}
                                        />
                                    </MessageBodyRow>

                                    <AppInput 
                                        placeholder='Subject' 
                                        name={'subject'} 
                                        handleChange={handleChange} 
                                        type={'text'} 
                                        handleBlur={handleBlur}
                                        errors={errors}
                                        touched={touched}
                                        value={values?.subject}
                                    />

                                    <AppInput 
                                        placeholder='Message' 
                                        name={'message'} 
                                        handleChange={handleChange} 
                                        type={'message'} 
                                        handleBlur={handleBlur}
                                        errors={errors}
                                        touched={touched}
                                        value={values?.message}
                                    />

                                    <AppButton title='Submit Now' type={'submit'} loading={loading} />
                                </Form>
                                )}
                            </Formik>
                        </MessageBodyContainer>
                    </MessageWrapper>
                </MessageContainer>
                <ContactInfoContainer>
                    <ContactInfoWrapper>
                        <ContactInfoIconContainer>
                            <ContactInfoIcon src={CallIcon}></ContactInfoIcon>
                        </ContactInfoIconContainer>
                        <ContactDetailContainer>
                            <ContactDetail>(808) 555-0111</ContactDetail>
                            <ContactDetail>(684) 555-0102</ContactDetail>
                        </ContactDetailContainer>
                    </ContactInfoWrapper>
                    <ContactInfoWrapper>
                        <ContactInfoIconContainer>
                            <ContactInfoIcon src={EmailIcon}></ContactInfoIcon>
                        </ContactInfoIconContainer>
                        <ContactDetailContainer>
                            <ContactDetail>alma.lawson@example.com</ContactDetail>
                            <ContactDetail>kenzi.lawson@example.com</ContactDetail>
                        </ContactDetailContainer>
                    </ContactInfoWrapper>
                    <ContactInfoWrapper>
                        <ContactInfoIconContainer>
                            <ContactInfoIcon src={LocationIcon}></ContactInfoIcon>
                        </ContactInfoIconContainer>
                        <ContactDetailContainer>
                            <ContactDetail>4517 Washington Ave.</ContactDetail>
                            <ContactDetail>Manchester, Kentucky 39495</ContactDetail>
                        </ContactDetailContainer>
                    </ContactInfoWrapper>
                </ContactInfoContainer>
            </ContactWrapper>
            <MapContainer>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "" }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                >
                    <AnyReactComponent
                        lat={59.955413}
                        lng={30.337844}
                        text="My Marker"
                    />
                </GoogleMapReact>
            </MapContainer>
        </ContactContainer>
    )
}

export default Contact
