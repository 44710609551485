import React, { useEffect, useState } from 'react';
import {
  RegisterContainer,
  RegisterFormContainer,
  RegisterFormBodyContainer,
  RegisterFormHeaderContainer,
  RegisterFormHeaderSubTitle,
  RegisterFormHeaderTitle,
  RegisterLeftContainer,
  RegisterRightContainer,
  RegisterSubTitle,
  RegisterTitle,
  RegisterTitleContainer,
  Form,
  LoginErrorLabel,
  ForgotPasswordLink
} from './Register.styled';
import AppReadMoreButton from '../../components/AppReadMoreButton/AppReadMoreButton';
import AppInput from '../../components/AppInput/AppInput';
import AppButton from '../../components/AppButton/AppButton';
import { emailIcon, nameIcon, passwordIcon, passwordShowIcon } from '../../assets/Register';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik } from "formik";
import * as Yup from "yup";
import { serverChangePassword, serverLogin, serverResetPasswordRequest } from '../../services/serverApi';
import { ICandidate } from '../../types/candidate';
import usePreviousRoute from '../../hooks/usePreviousRoute';

const Register = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const previousRoute = usePreviousRoute();

  const handleSubmitData = async (data: ICandidate) => {
    try {
      setLoading(true);
      const res = await serverLogin(data);
      setLoading(false);
      if (res?.success) {
        setError(null);
        localStorage.setItem('candidate', JSON.stringify(res?.data));
        localStorage.setItem('isActive', JSON.stringify(true));
        window.location.reload();
        navigate('/profile');
      }
    } catch (err: any) {
      setLoading(false);
      setError(err?.response?.data?.message);
      console.log(err);
    }
  };

  const handleSubmitRequest = async (data: {email: string}) => {
    try {
      setLoading(true);
      const res = await serverResetPasswordRequest(data);
      if (res?.success) {
        localStorage.setItem('email', data?.email);
        navigate('/change/password');
      } else {
        console.log('res', res)
        setError(res?.message);
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      setError(err?.response?.data?.message);
      console.log(err);
    }
  }

  const handleSubmitChangePassword = async (data: {repassword: string, password: string}) => {
    try {
      setLoading(true);
      const localEmail = localStorage.getItem('email');
      const res = await serverChangePassword({password: data?.password, email: localEmail ?? ''});
      if (res?.success) {
        navigate('/')
        localStorage.removeItem('email')
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      setError(err?.response?.data?.message);
      console.log(err);
    }
  }

  const registerSchema = Yup.object().shape({
    name: Yup.string().required("Please enter a full name.*"),
    email: Yup.string().email("Please enter a valid email address.*").required("Please enter an email.*"),
    password: Yup.string().required("Please enter a password.*"),
    repassword: Yup.string()
      .required("Please confirm the password.*")
      .oneOf([Yup.ref('password'), ''], "Passwords do not match.*")
  });

  const loginSchema = Yup.object().shape({
    email: Yup.string().email("Please enter a valid email address.*").required("Please enter an email.*"),
    password: Yup.string().required("Please enter a password.*")
  });

  const resetRequestSchema = Yup.object().shape({
    email: Yup.string().email("Please enter a valid email address.*").required("Please enter an email.*"),
  });

  const changePasswordSchema = Yup.object().shape({
    password: Yup.string().required("Please enter a password.*"),
    repassword: Yup.string()
      .required("Please confirm the password.*")
      .oneOf([Yup.ref('password'), ''], "Passwords do not match.*")
  });

  useEffect(() => {
    if (location.pathname === '/change/password') {
      const emailData = localStorage.getItem('email');
      if (!emailData) {
        navigate(-1);
      }
    }
  }, [location.pathname])

  const getRegisterBody = () => (
    <RegisterFormContainer>
      <RegisterFormHeaderContainer>
        <RegisterFormHeaderTitle>Hello!</RegisterFormHeaderTitle>
        <RegisterFormHeaderSubTitle>Sign Up to Get Started</RegisterFormHeaderSubTitle>
      </RegisterFormHeaderContainer>
      <RegisterFormBodyContainer>
        <Formik
          validationSchema={registerSchema}
          initialValues={{
            name: '',
            email: '',
            password: '',
            repassword: ''
          }}
          onSubmit={(values) => {}}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit
          }) => (
            <Form onSubmit={handleSubmit}>
              <AppInput
                placeholder='Full Name'
                name='name'
                handleChange={handleChange}
                type='text'
                inputIcon={nameIcon}
                handleBlur={handleBlur}
                errors={errors}
                touched={touched}
                value={values.name}
              />
              <AppInput
                placeholder='Email Address'
                name='email'
                handleChange={handleChange}
                type='text'
                inputIcon={emailIcon}
                handleBlur={handleBlur}
                errors={errors}
                touched={touched}
                value={values.email}
              />
              <AppInput
                placeholder='Password'
                name='password'
                handleChange={handleChange}
                type='password'
                inputIcon={passwordIcon}
                passwordIcon={passwordShowIcon}
                handleBlur={handleBlur}
                errors={errors}
                touched={touched}
                value={values.password}
              />
              <AppInput
                placeholder='Confirm Password'
                name='repassword'
                handleChange={handleChange}
                type='password'
                inputIcon={passwordIcon}
                passwordIcon={passwordShowIcon}
                handleBlur={handleBlur}
                errors={errors}
                touched={touched}
                value={values.repassword}
              />
              <AppButton title='Register' type='submit' />
            </Form>
          )}
        </Formik>
      </RegisterFormBodyContainer>
    </RegisterFormContainer>
  );

  const getLoginBody = () => (
    <RegisterFormContainer>
      <RegisterFormHeaderContainer>
        <RegisterFormHeaderTitle>Login Now</RegisterFormHeaderTitle>
        <RegisterFormHeaderSubTitle>Welcome Back</RegisterFormHeaderSubTitle>
      </RegisterFormHeaderContainer>
      {error && (<LoginErrorLabel>{error}</LoginErrorLabel>)}
      <RegisterFormBodyContainer>
        <Formik
          validationSchema={loginSchema}
          initialValues={{
            email: '',
            password: ''
          }}
          onSubmit={(values) => handleSubmitData(values)}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit
          }) => (
            <Form onSubmit={handleSubmit}>
              <AppInput
                placeholder='Email Address'
                name='email'
                handleChange={handleChange}
                type='text'
                inputIcon={emailIcon}
                handleBlur={handleBlur}
                errors={errors}
                touched={touched}
                value={values.email}
              />
              <AppInput
                placeholder='Password'
                name='password'
                handleChange={handleChange}
                type='password'
                inputIcon={passwordIcon}
                passwordIcon={passwordShowIcon}
                handleBlur={handleBlur}
                errors={errors}
                touched={touched}
                value={values.password}
              />
              <AppButton title='Login' type='submit' loading={loading} />
            </Form>
          )}
        </Formik>
      </RegisterFormBodyContainer>
      <ForgotPasswordLink onClick={() => navigate('/reset/request')}>Forgot Password</ForgotPasswordLink>
    </RegisterFormContainer>
  );

  const getResetRequestBody = () => (
    <RegisterFormContainer>
      <RegisterFormHeaderContainer>
        <RegisterFormHeaderTitle>Submit Reset Request</RegisterFormHeaderTitle>
        <RegisterFormHeaderSubTitle>Sign Up to Get Started</RegisterFormHeaderSubTitle>
      </RegisterFormHeaderContainer>
      <RegisterFormBodyContainer>
        <Formik
          validationSchema={resetRequestSchema}
          initialValues={{
            email: '',
          }}
          onSubmit={(values) => handleSubmitRequest(values)}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit
          }) => (
            <Form onSubmit={handleSubmit}>
              <AppInput
                placeholder='Email Address'
                name='email'
                handleChange={handleChange}
                type='text'
                inputIcon={emailIcon}
                handleBlur={handleBlur}
                errors={errors}
                touched={touched}
                value={values.email}
              />
              <AppButton title='Submit' type='submit' loading={loading} />
            </Form>
          )}
        </Formik>
      </RegisterFormBodyContainer>
    </RegisterFormContainer>
  );

  const getChangePasswordBody = () => (
    <RegisterFormContainer>
      <RegisterFormHeaderContainer>
        <RegisterFormHeaderTitle>Change Password</RegisterFormHeaderTitle>
        <RegisterFormHeaderSubTitle>Sign Up to Get Started</RegisterFormHeaderSubTitle>
      </RegisterFormHeaderContainer>
      <RegisterFormBodyContainer>
        <Formik
          validationSchema={changePasswordSchema}
          initialValues={{
            password: '',
            repassword: ''
          }}
          onSubmit={(values) => handleSubmitChangePassword(values)}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit
          }) => (
            <Form onSubmit={handleSubmit}>
              <AppInput
                placeholder='Password'
                name='password'
                handleChange={handleChange}
                type='password'
                inputIcon={passwordIcon}
                passwordIcon={passwordShowIcon}
                handleBlur={handleBlur}
                errors={errors}
                touched={touched}
                value={values.password}
              />
              <AppInput
                placeholder='Confirm Password'
                name='repassword'
                handleChange={handleChange}
                type='password'
                inputIcon={passwordIcon}
                passwordIcon={passwordShowIcon}
                handleBlur={handleBlur}
                errors={errors}
                touched={touched}
                value={values.repassword}
              />
              <AppButton title='Submit' type='submit' />
            </Form>
          )}
        </Formik>
      </RegisterFormBodyContainer>
    </RegisterFormContainer>
  );

  const getBodyData = (pathname: string): React.ReactNode => {
    console.log('pathname', pathname);
    switch (pathname) {
      case '/register':
        return getRegisterBody();
      case '/Login':
        return getLoginBody();
      case '/login':
        return getLoginBody();
      case '/reset/request': 
        return getResetRequestBody();
      case '/change/password':
        return getChangePasswordBody();
      default:
        return getLoginBody();
    }
  }

  const bodyContent: React.ReactNode = getBodyData(location.pathname);

  return (
    <RegisterContainer>
      <RegisterLeftContainer>
        <RegisterTitleContainer>
          <RegisterTitle>Welcome To{'\n'}Educator</RegisterTitle>
          <RegisterSubTitle>The most popular peer to peer lending at SEA</RegisterSubTitle>
          <AppReadMoreButton title='Read More'/>
        </RegisterTitleContainer>
      </RegisterLeftContainer>
      <RegisterRightContainer>
        {bodyContent}
      </RegisterRightContainer>
    </RegisterContainer>
  );
};

export default Register;
