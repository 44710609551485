import React from 'react';
import { AppInputContainer, AppInputWrapper, Icon, InputLabelAndErrorContainer, StyledInput } from './AppInput.styled';
import { nameIcon } from '../../assets/Register';
import { error } from 'console';

interface Props {
  placeholder: string;
  inputIcon?: any;
  passwordIcon?: any;
  name: string;
  handleChange: (e: any) => void;
  type: string;
  value?: string;
  handleBlur?: (e: any) => void;
  errors?: any;
  touched?: any;
  handlePasswordIconClick?: () => void;
}

const AppInput: React.FC<Props> = ({ placeholder, inputIcon, passwordIcon, handleChange, name, type, handleBlur, errors, touched, value, handlePasswordIconClick }) => {
  return (
    <AppInputWrapper>
      <InputLabelAndErrorContainer>
        {/* <TextLabel>{label} <TextLabelSpan>{isRequired ? '*' : ''}</TextLabelSpan></TextLabel> */}
        {errors && touched && (
          <div style={{ color: "red", fontStyle: "italic", fontSize: 12 }}>
            {errors[name] && touched[name] && errors[name]}
          </div>
        )}
      </InputLabelAndErrorContainer>
      <AppInputContainer>
        {inputIcon && (
          <Icon src={inputIcon} alt="icon" />
        )}
        <StyledInput 
          placeholder={placeholder} 
          name={name}
          onChange={handleChange}
          type={type}
          value={value}
          onBlur={handleBlur}
        />
        {passwordIcon && (
          <Icon src={passwordIcon} onClick={handlePasswordIconClick} alt="icon" />
        )}
      </AppInputContainer>
    </AppInputWrapper>
  );
}

export default AppInput;