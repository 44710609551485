import React, { useState } from 'react'
import { ChangePasswordContainer, ChangePasswordTitle, Form, OlaPasswordContainer, OldPassWordLable, PasswordInput } from './ChangePassword.styled'
import { passwordHideIcon, passwordIcon, passwordShowIcon } from '../../assets/Register'
import AppInput from '../../components/AppInput/AppInput'
import useChangePassword from './useChangePassword'
import { Formik } from "formik";
import AppButton from '../../components/AppButton/AppButton'

const ChangePassword = () => {
    const {
        handleOldpasswordIconToggle,
        oldPasswordVisible,
        changePasswordSchema,
        handleNewpasswordIconToggle,
        handleRepasswordIconToggle,
        newPasswordVisible,
        rePasswordVisible,
        adminData,
        handleSubmitChangePassword,
        loading
    } = useChangePassword();

    return (
        <ChangePasswordContainer>
            <OlaPasswordContainer>
                <OldPassWordLable>Old Password</OldPassWordLable>
                <AppInput
                    placeholder='Password'
                    name='password'
                    handleChange={() => {}}
                    type={oldPasswordVisible ? 'text' : 'password'}
                    inputIcon={passwordIcon}
                    passwordIcon={oldPasswordVisible ? passwordHideIcon : passwordShowIcon}
                    value={adminData?.password}
                    handlePasswordIconClick={handleOldpasswordIconToggle}
                />
            </OlaPasswordContainer>
            <ChangePasswordTitle>
                Change Password
            </ChangePasswordTitle>
            <Formik
                validationSchema={changePasswordSchema}
                initialValues={{
                    password: '',
                    repassword: ''
                }}
                onSubmit={handleSubmitChangePassword}
                >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <AppInput
                            placeholder='Password'
                            name='password'
                            handleChange={handleChange}
                            type={newPasswordVisible ? 'test' : 'password'}
                            inputIcon={passwordIcon}
                            passwordIcon={newPasswordVisible ? passwordHideIcon : passwordShowIcon}
                            handleBlur={handleBlur}
                            errors={errors}
                            touched={touched}
                            value={values.password}
                            handlePasswordIconClick={handleNewpasswordIconToggle}
                        />
                        <AppInput
                            placeholder='Confirm Password'
                            name='repassword'
                            handleChange={handleChange}
                            type={rePasswordVisible ? 'test' : 'password'}
                            inputIcon={passwordIcon}
                            passwordIcon={rePasswordVisible ? passwordHideIcon : passwordShowIcon}
                            handleBlur={handleBlur}
                            errors={errors}
                            touched={touched}
                            value={values.repassword}
                            handlePasswordIconClick={handleRepasswordIconToggle}
                        />
                        <AppButton title='Submit' type='submit' loading={loading} />
                    </Form>
                )}
            </Formik>
        </ChangePasswordContainer>
    )
}

export default ChangePassword