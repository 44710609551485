import React from 'react'
import { DashboardSummaryContainer, ProfileCardContainer, ProfileCardHeaderContainer, ProfileCardHeaderWrapper, ProfileDashboardContainer, ProfileDashboardWrapper, ProfileDescription, ProfileEditButton, ProfileImg, ProfileName, SummaryBoxContainer, SummaryBoxCount, SummaryBoxTitle, TableContainer, TableTitle } from './ProfileDashboard.styled'
import useProfileDashboard from './useProfileDashboard'
import { SummaryData } from '../../utils/consts/profileDashboard';
import { useNavigate } from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table'
import ExamNoticeDialog from '../../dialogs/ExamNoticeDialog/ExamNoticeDialog';
import ProfileMatchDialog from '../../dialogs/ProfileMatchDialog/ProfileMatchDialog';

const ProfileDashboard = () => {
    const navigate = useNavigate();
    const {
        adminData,
        table,
        openNoticeDialog,
        handleCloseNoticeDialog,
        handleOpenProfileMatchDialog,
        handleCloseProfileMatchDialog,
        openProfileMatchDialog,
        selectedExamData,
        dashboardData
    } = useProfileDashboard();

    console.log('dahboardExam', dashboardData)

    return (
        <ProfileDashboardContainer>
            <ProfileDashboardWrapper>
                <ProfileCardContainer>
                    <ProfileCardHeaderContainer>
                        <ProfileCardHeaderWrapper>
                            <ProfileImg src={adminData?.profileImg}></ProfileImg>
                            <ProfileName>{adminData?.firstName}</ProfileName>
                        </ProfileCardHeaderWrapper>
                        <ProfileEditButton onClick={() => navigate('/edit-profile')}>Edit</ProfileEditButton>
                    </ProfileCardHeaderContainer>
                    <ProfileDescription>{'Hello I am the professional fullstake Developer from oxferd university'}</ProfileDescription>
                </ProfileCardContainer>
                <DashboardSummaryContainer>
                    {SummaryData?.map((item) => {
                        return (
                            <SummaryBoxContainer bgColor={item?.bgColor}>
                                <SummaryBoxTitle>{item?.title}</SummaryBoxTitle>
                                <SummaryBoxCount>{dashboardData && dashboardData[item?.type]}</SummaryBoxCount>
                            </SummaryBoxContainer>
                        )
                    })}
                </DashboardSummaryContainer>
            </ProfileDashboardWrapper>

            <TableContainer>
                <TableTitle>Recent Exam Assigned</TableTitle>
                <MaterialReactTable table={table} />
            </TableContainer>

            {openNoticeDialog && (
                <ExamNoticeDialog open={openNoticeDialog} handleClose={handleCloseNoticeDialog} handleOpenProfileMatchDialog={handleOpenProfileMatchDialog} />
            )}

            {openProfileMatchDialog && (
                <ProfileMatchDialog open={openProfileMatchDialog} handleClose={handleCloseProfileMatchDialog} selectedExamData={selectedExamData} />
            )}
        </ProfileDashboardContainer>
    )
}

export default ProfileDashboard