import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
import { Form, RequestDemoDialogContainer } from './RequestDemoDialog.styled';
import { Formik } from "formik";
import * as Yup from "yup";
import AppInput from '../../components/AppInput/AppInput';
import AppButton from '../../components/AppButton/AppButton';
import { serverSentDemoRequest } from '../../services/serverApi';
import { IDemoRequest } from '../../types/demo';
import useToast from '../../hooks/useToast';

interface Props {
    open: boolean;
    handleClose: () => void;
}

const RequestDemoDialog: React.FC<Props> = ({ open, handleClose }) => {
    const { showToast } = useToast(); // Destructure the hook
    const requestSchema = Yup.object().shape({
        companyName: Yup.string().required("Please enter a company name.*"),
        name: Yup.string().required("Please enter a name.*"),
        email: Yup.string().email("Please enter a valid email address.*").required("Please enter an email.*"),
        number: Yup.string().required("Please enter a mobile number.*")
    });
    const [loading, setLoading] = React.useState(false);

    const handleRequestSubmit = async (data: any) => {
        try {
            setLoading(true);
            await serverSentDemoRequest({...data, isDemoRequest: true});
            showToast('success', 'Message sent successfully!');
            handleClose();
            setLoading(false);
        } catch (err) {
            showToast('error', 'Something went wrong. Please try again later.');
            setLoading(false);
            console.log(err);
        }
    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll={'body'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">Demo Request</DialogTitle>
            <DialogContent dividers={true}>
                <RequestDemoDialogContainer>
                    <Formik
                        validationSchema={requestSchema}
                        initialValues={{
                            companyName: '',
                            name: '',
                            email: '',
                            number: ''
                        }}
                        onSubmit={(values) => handleRequestSubmit(values)}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <AppInput
                                    placeholder='Comapny Name / Organization Name'
                                    name='companyName'
                                    handleChange={handleChange}
                                    type='text'
                                    // inputIcon={nameIcon}
                                    handleBlur={handleBlur}
                                    errors={errors}
                                    touched={touched}
                                    value={values.companyName}
                                />
                                <AppInput
                                    placeholder='Name'
                                    name='name'
                                    handleChange={handleChange}
                                    type='text'
                                    // inputIcon={emailIcon}
                                    handleBlur={handleBlur}
                                    errors={errors}
                                    touched={touched}
                                    value={values.name}
                                />
                                <AppInput
                                    placeholder='Email'
                                    name='email'
                                    handleChange={handleChange}
                                    type='email'
                                    // inputIcon={emailIcon}
                                    handleBlur={handleBlur}
                                    errors={errors}
                                    touched={touched}
                                    value={values.email}
                                />
                                <AppInput
                                    placeholder='Mobile Number'
                                    name='number'
                                    handleChange={handleChange}
                                    type='number'
                                    // inputIcon={emailIcon}
                                    handleBlur={handleBlur}
                                    errors={errors}
                                    touched={touched}
                                    value={values.number}
                                />
                                <AppButton title='Send Request' type='submit' loading={loading} />
                            </Form>
                        )}
                    </Formik>
                </RequestDemoDialogContainer>
            </DialogContent>
        </Dialog>
    )
}

export default RequestDemoDialog