import React, { Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Register from '../Register/Register';
import AppHeader from '../../components/AppHeader/AppHeader';
import AppFooter from '../../components/AppFooter/AppFooter';
import Faqs from '../Faqs/Faqs';
import Contact from '../Contact/Contact';
import Home from '../Home/Home';
import useToast from '../../hooks/useToast'; // Import the custom hook

const Main = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { ToastProvider } = useToast(); // Destructure the hook

  const getRoutes = () => {
    return (
      <Suspense>
        <Routes location={location}>
          <Route path="*" element={<Navigate to={'/'} />} />
          <Route
            path="/register"
            element={
              <Suspense>
                <Register />
              </Suspense>
            }
          />
          <Route
            path="/login"
            element={
              <Suspense>
                <Register />
              </Suspense>
            }
          />
          <Route
            path="/reset/request"
            element={
              <Suspense>
                <Register />
              </Suspense>
            }
          />
          <Route
            path="/change/password"
            element={
              <Suspense>
                <Register />
              </Suspense>
            }
          />
          <Route
            path="/home/faqs"
            element={
              <Suspense>
                <Faqs />
              </Suspense>
            }
          />
          <Route
            path="/home/contact"
            element={
              <Suspense>
                <Contact />
              </Suspense>
            }
          />
          <Route
            path="/"
            element={
              <Suspense>
                <Home />
              </Suspense>
            }
          />
        </Routes>
      </Suspense>
    );
  };

  useEffect(() => {
    const adminDataString = localStorage.getItem('candidate');
    if (adminDataString) {
      if (location.pathname === '/Login') {
        navigate('/');
      }
      try {
        const adminData = JSON.parse(adminDataString);
      } catch (error) {
        console.error("Error parsing admin data:", error);
      }
    } else {
      // navigate('')
    }
  }, [location.pathname]);

  return (
    <>
      <AppHeader />
      {getRoutes()}
      <AppFooter />
      <ToastProvider /> {/* Add ToastProvider here */}
    </>
  );
};

export default Main;
