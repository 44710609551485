import React from 'react'
import { AppReadMoreButtonContainer } from './AppReadMoreButton.styled'

interface Props {
    title: string;
}

const AppReadMoreButton: React.FC<Props> = ({ title }) => {
  return (
    <AppReadMoreButtonContainer>
        {title}
    </AppReadMoreButtonContainer>
  )
}

export default AppReadMoreButton