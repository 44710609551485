import styled from "styled-components";

export const HomeContainer = styled.div`
padding: 50px 100px 0px 100px;
display: flex;
flex-direction: column;
gap: 20px;

@media screen and (max-width: 850px) {
    padding: 25px 50px 0px 50px;
}
`
export const ExamSecurityContainer = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
height: auto;
gap: 50px;
/* height: auto; */
/* background-color: red; */

@media screen and (max-width: 850px) {
  flex-direction: column;
}
`
export const ExamSecurityLeftContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
gap: 50px;
`
export const ExamSecurityRightContainer = styled.div`
width: 100%;
/* max-height: 498px; */
/* position: relative; */
display: flex;
flex-direction: column;
align-items: center;
`
export const ExamSecurityTitle = styled.div`
font: Readex Pro;
font-weight: 700;
font-size: 60px;
color: #011D0D;
max-width: 527px;
`
export const ExamSecurityDescription = styled.div`
font: Readex Pro;
font-weight: 400;
font-size: 24px;
color: #435169;
max-width: 471px;
`
export const ExamSecuritySubDescription = styled.div`
width: 336px;
font: Readex Pro;
font-weight: 400;
font-size: 20px;
`
// export const ExamSecurityImage = styled.div<{ bgImg: string }>`
// background-image: ${({ bgImg }) => `url(${bgImg})`};
// background-size: cover;
// background-position: center;
// height: 438px;
// width: 574px;
// position: absolute;
// top: 0px;
// border-radius: 24px;
// z-index: 1;
// `
export const ExamSecurityImage = styled.img`
width: 100%;
height: 100%;
border-radius: 24px;
`
export const ExamSecurityRightWrapper = styled.div`
width: 654px;
height: 266px;
background-color: #010B1C;
border-radius: 24px;
position: absolute;
bottom: 0px;
`
export const ExamSecurityRightWrapperIcon = styled.img`
position: absolute;
bottom: 0px;
right: 0px;
`
export const OurMissionContainer = styled.div`
display: flex;
align-items: center;
gap: 50px;
/* background-color: red; */

@media screen and (max-width: 850px) {
  flex-direction: column;
}
`
export const OurMissionLeftContainer = styled.div`
width: 100%;
`
export const OurMissionLeftWrapper = styled.div`
width: 100%;
/* height: 463px; */
border-radius: 24px;
background: #E9EDF0;
display: flex;
align-items: center;
justify-content: center;
`
export const OurMissionRightContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;

@media screen and (max-width: 850px) {
    align-items: flex-start;
}
`
export const OurMissionRightWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 10px;
`
export const OurMissionImage = styled.img`
width: 100%;
height: 100%;
border-radius: 24px;
`
export const OurMissionTitle = styled.div`
width: 272px;
font: Readex Pro;
font-weight: 600;
font-size: 46px;
color: #3F87FE;
`
export const OurMissionDescription = styled.div`
width: 501px;
font: Readex Pro;
font-weight: 600;
font-size: 46px;
color: #011D0D;
`
export const OurVisionContainer = styled.div`
display: flex;
align-items: center;
gap: 50px;
/* background-color: red; */

@media screen and (max-width: 850px) {
  flex-direction: column;
}
`
export const OurVisionLeftContainer = styled.div`
width: 100%;
`
export const OurVisionLeftWrapper = styled.div`
width: 100%;
height: auto;
border-radius: 24px;
background: #E9EDF0;
display: flex;
align-items: center;
justify-content: center;
`
export const OurVisionRightContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
`
export const OurVisionRightWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 10px;
`
export const OurVisionImage = styled.img`
width: 100%;
height: 100%;
border-radius: 24px;
`
export const OurVisionTitle = styled.div`
max-width: 272px;
font: Readex Pro;
font-weight: 600;
font-size: 46px;
color: #3F87FE;
`
export const OurVisionDescription = styled.div`
max-width: 501px;
font: Readex Pro;
font-weight: 600;
font-size: 46px;
color: #011D0D;
`
export const ExamProctoringContainer = styled.div`
display: flex;
align-items: center;
gap: 50px;
/* background-color: red; */

@media screen and (max-width: 850px) {
  flex-direction: column;
}
`
export const ExamProctoringLeftContainer = styled.div`
width: 100%;
`
export const ExamProctoringLeftWrapper = styled.div`
/* width: 510px;
height: 463px; */
width: 100%;
border-radius: 24px;
background: #E9EDF0;
display: flex;
align-items: center;
justify-content: center;
`
export const ExamProctoringRightContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;

@media screen and (max-width: 850px) {
 align-items: flex-start;
}
`
export const ExamProctoringRightWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 10px;
`
export const ExamProctoringImage = styled.img`
width: 100%;
height: 100%;
border-radius: 24px;
`
export const ExamProctoringTitle = styled.div`
max-width: 382px;
font: Readex Pro;
font-weight: 600;
font-size: 46px;
color: #011D0D;
`
export const ExamProctoringDescription = styled.div`
max-width: 448px;
font: Readex Pro;
font-weight: 400;
font-size: 20px;
color: #435169;
`
export const ExamProctoringUl = styled.ul`
display: flex;
flex-direction: column;
gap: 10px;
`
export const ExamProctoringLi = styled.li`
font: Readex Pro;
font-weight: 400;
font-size: 20px;
color: #435169;
`
export const ContentProtectionContainer = styled.div`
display: flex;
align-items: center;
background-color: #3F87FE;
margin-left: -100px;
margin-right: -100px;
padding: 40px 100px;
gap: 50px;

@media screen and (max-width: 850px) {
 flex-direction: column;
 margin-right: -50px;
}
`
export const ContentProtectionLeftContainer = styled.div`
width: 100%;
`
export const ContentProtectionLeftWrapper = styled.div`
/* width: 510px;
height: 463px; */
width: 100%;
border-radius: 24px;
background: #E9EDF0;
display: flex;
align-items: center;
justify-content: center;
`
export const ContentProtectionRightContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
`
export const ContentProtectionRightWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 10px;
`
export const ContentProtectionImage = styled.img`
width: 100%;
height: 50%;
border-radius: 24px;
`
export const ContentProtectionTitle = styled.div`
max-width: 680px;
font: Readex Pro;
font-weight: 600;
font-size: 46px;
color: #FFFFFF;
`
export const ContentProtectionDescription = styled.div`
width: 501px;
font: Readex Pro;
font-weight: 400;
font-size: 20px;
color: #FFFFFF;
`
export const ContentProtectionUl = styled.ul`
display: flex;
flex-direction: column;
gap: 10px;
`
export const ContentProtectionLi = styled.li`
font: Readex Pro;
font-weight: 400;
font-size: 20px;
color: #FFFFFF;
`