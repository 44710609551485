import React from 'react'
import { MaterialReactTable } from 'material-react-table'
import useExamTable from './useExamTable'
import ExamNoticeDialog from '../../dialogs/ExamNoticeDialog/ExamNoticeDialog';
import ProfileMatchDialog from '../../dialogs/ProfileMatchDialog/ProfileMatchDialog';

const ExamTable = () => {
    const {
      table,
      handleCloseNoticeDialog,
      openNoticeDialog,
      selectedExamData,
      handleOpenProfileMatchDialog,
      openProfileMatchDialog,
      handleCloseProfileMatchDialog
    } = useExamTable();
  return (
    <div>
      <MaterialReactTable table={table} />

      {openNoticeDialog && (
        <ExamNoticeDialog open={openNoticeDialog} handleClose={handleCloseNoticeDialog} handleOpenProfileMatchDialog={handleOpenProfileMatchDialog} />
      )}

      {openProfileMatchDialog && (
        <ProfileMatchDialog open={openProfileMatchDialog} handleClose={handleCloseProfileMatchDialog} selectedExamData={selectedExamData} />
      )}
    </div>
  )
}

export default ExamTable