import styled from "styled-components";

export const AppNavigationContainer = styled.div<{ bgImage: string; }>`
height: 487px;
width: 100%;
background-image: ${({ bgImage }) => `url(${bgImage})`};
background-size: cover;
background-position: center;
display: flex;
align-items: center;
`
export const AppNavigationWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 25px;
margin-left: 40px;
`
export const AppNavigationTitle = styled.div`
font: Urbanist;
font-weight: 700;
font-size: 56px;
color: #FFFFFF;
`
export const AppNavigationPath = styled.div`
height: 50px;
border-radius: 6px;
background-color: #0575E6;
font: Urbanist;
font-weight: 400;
font-size: 18px;
color: #FFFFFF;
width: fit-content;
display: flex;
align-items: center;
padding: 0px 20px;
`
export const PathSegment = styled.span`
    text-transform: capitalize;
    &:not(:last-child)::after {
        content: ' / ';
        margin: 0 5px;
    }
`;