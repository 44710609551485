import React from 'react'
import { AppNavigationContainer, AppNavigationPath, AppNavigationTitle, AppNavigationWrapper, PathSegment } from './AppNavigation.styled'
import { useLocation } from 'react-router-dom';

interface Props {
  bgImage: string;
  title: string;
}

const AppNavigation: React.FC<Props> = ({ bgImage, title }) => {
  const location = useLocation();
  const segments = location?.pathname?.split('/').filter(segment => segment);

  return (
    <AppNavigationContainer bgImage={bgImage}>
      <AppNavigationWrapper>
        <AppNavigationTitle>{title}</AppNavigationTitle>
        <AppNavigationPath>
            {segments.map((segment, index) => (
              <PathSegment key={index}>{segment}</PathSegment>
            ))}
        </AppNavigationPath>
      </AppNavigationWrapper>
    </AppNavigationContainer>
  )
}

export default AppNavigation