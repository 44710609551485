import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import { ICandidate } from '../../types/candidate';
import { serverChangePassword } from '../../services/serverApi';

const useChangePassword = () => {
    const [oldPasswordVisible, setOldPasswordVisible] = useState<boolean>(false);
    const [newPasswordVisible, setNewPasswordVisible] = useState<boolean>(false);
    const [rePasswordVisible, setRePasswordVisible] = useState<boolean>(false);
    const [adminData, setAdminData] = useState<ICandidate>();
    const [loading, setLoading] = useState<boolean>(false);

    const handleOldpasswordIconToggle = () => {
        setOldPasswordVisible(!oldPasswordVisible)
    }

    const handleNewpasswordIconToggle = () => {
        setNewPasswordVisible(!newPasswordVisible)
    }

    const handleRepasswordIconToggle = () => {
        setRePasswordVisible(!rePasswordVisible)
    }

    const changePasswordSchema = Yup.object().shape({
        password: Yup.string().required("Please enter a password.*"),
        repassword: Yup.string()
          .required("Please confirm the password.*")
          .oneOf([Yup.ref('password'), ''], "Passwords do not match.*")
    });

    const handleSubmitChangePassword = async (data: {repassword: string, password: string}, actions: any) => {
        try {
          setLoading(true);
          const res = await serverChangePassword({password: data?.password, email: adminData?.email ?? ''});
          if (res?.success) {
            localStorage.removeItem('candidate')
            localStorage.setItem('candidate', JSON.stringify({...adminData, password: data?.password}));
            const adminDataString = localStorage.getItem('candidate');
            if (adminDataString) {
                try {
                    const adminData = JSON.parse(adminDataString);
                    setAdminData(adminData);
                } catch (error) {
                    console.error("Error parsing admin data:", error);
                }
            } else {
                setAdminData(undefined);
            }
          }
          setOldPasswordVisible(false);
          setNewPasswordVisible(false);
          setRePasswordVisible(false);
          actions.resetForm();
          setLoading(false);
        } catch (err: any) {
          setLoading(false);
          console.log(err);
        }
    }

    useEffect(() => {
        const adminDataString = localStorage.getItem('candidate');
        if (adminDataString) {
          try {
            const adminData = JSON.parse(adminDataString);
            setAdminData(adminData);
          } catch (error) {
            console.error("Error parsing admin data:", error);
          }
        } else {
          setAdminData(undefined);
        }
    }, []);

    return {
        handleOldpasswordIconToggle,
        oldPasswordVisible,
        changePasswordSchema,
        handleNewpasswordIconToggle,
        handleRepasswordIconToggle,
        newPasswordVisible,
        rePasswordVisible,
        adminData,
        handleSubmitChangePassword,
        loading
    }
}

export default useChangePassword