import { title } from "process";
import { ExamStatusType } from "../../types/exam";

export const SummaryData = [
    {
        title: 'Pending Exam',
        bgColor: '#3F87FE',
        type: 'pendingExam'
    },
    {
        title: 'Completed Exam',
        bgColor: '#FABB05',
        type: 'completeExam'
    },
    {
        title: 'Exam Failed',
        bgColor: '#F44336',
        type: 'failedExam'
    },
    {
        title: 'Exam Passed',
        bgColor: '#0EAB0E',
        type: 'passedExam'
    }
]