// src/Footer.js
import React from 'react';
import styled from 'styled-components';
import { callIcon, emailIcon, locationIcon } from '../../assets/Footer';
import { AppFooterContainer, AppFooterDescription, AppFooterDescriptionContainer, AppFooterInformationContainer, AppFooterLogo, AppFooterLogoContainer, AppFooterWrapper, Contact, ContactIcon, ContactInfo, DayTime, EmailSupport, FooterLink, FooterLinks, GetStartButton, Holiday, Hr, Mail, MailIcon, MailInfo, PhoneSupport, Reserved, ReservedContainer, SupportContainer } from './AppFooter.styled';

// const FooterContainer = styled.footer`
//   background-color: #1e1e1e;
//   color: #fff;
//   padding: 40px;
//   display: flex;
//   justify-content: space-between;
//   flex-wrap: wrap;
//   @media (max-width: 768px) {
//     flex-direction: column;
//     text-align: center;
//   }
// `;

// const FooterSection = styled.div`
//   flex: 1;
//   margin: 10px;
//   min-width: 200px;
// `;

// const LogoContainer = styled.div`
//   display: flex;
//   align-items: center;
//   margin-bottom: 20px;
//   width: 120px;
//   cursor: pointer;
// `;

// const LogoIcon = styled.div`
//   width: 40px;
//   height: 40px;
//   background-color: #fff;
//   margin-right: 10px;
//   border-radius: 50%;
// `;

// const LogoText = styled.h2`
//   margin: 0;
// `;

// const ContactInfo = styled.div`
//   margin-top: 20px;
// `;

// const ContactItem = styled.div`
//   display: flex;
//   align-items: center;
//   margin-bottom: 20px;
//   font-size: 14px;
//   gap: 20px;
// `;

// const Icon = styled.div`
//   width: 24px;
//   height: 24px;
//   margin-right: 10px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

// const PhoneIcon = styled(Icon)`
//   background-image: url('data:image/svg+xml;charset=UTF-8,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%2339B54A%27 viewBox=%270 0 24 24%27%3e%3cpath d=%27M6.62 10.79a15.478 15.478 0 006.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.27 1.12.28 2.33.43 3.57.43.55 0 1 .45 1 1V20c0 .55-.45 1-1 1C9.92 21 3 14.08 3 6c0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.24.15 2.45.43 3.57.09.35 0 .74-.28 1.02l-2.03 2.2z%27/%3e%3c/svg%3e');
//   background-size: contain;
// `;

// const EmailIcon = styled(Icon)`
//   background-image: url('data:image/svg+xml;charset=UTF-8,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%234E7DFF%27 viewBox=%270 0 24 24%27%3e%3cpath d=%27M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4.99L12 13 4 8.99V6l8 5 8-5v2.99z%27/%3e%3c/svg%3e');
//   background-size: contain;
// `;

// const AddressIcon = styled(Icon)`
//   background-image: url('data:image/svg+xml;charset=UTF-8,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%23FF5A5F%27 viewBox=%270 0 24 24%27%3e%3cpath d=%27M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5s2.5 1.12 2.5 2.5S13.38 11.5 12 11.5z%27/%3e%3c/svg%3e');
//   background-size: contain;
// `;

// const FooterLinks = styled.ul`
//   list-style: none;
//   padding: 0;
// `;

// const FooterLink = styled.li`
//   margin-bottom: 10px;
// `;

// const SubscribeSection = styled.div`
//   text-align: left;
//   @media (max-width: 768px) {
//     margin-top: 20px;
//     text-align: center;
//   }
// `;

// const SubscribeTitle = styled.h3`
//   margin-bottom: 10px;
//   font-size: 18px;
// `;

// const SubscribeText = styled.p`
//   margin-bottom: 20px;
//   font-size: 14px;
//   line-height: 1.6;
// `;

// const SubscribeButton = styled.button`
//   background-color: #007bff;
//   color: #fff;
//   border: none;
//   padding: 10px 20px;
//   cursor: pointer;
//   border-radius: 5px;
// `;

const Footer = () => {
  return (
    // <FooterContainer>
    //   <FooterSection>
    //     <LogoContainer onClick={() => window.location.reload()}>
    //       {/* <LogoIcon />
    //       <LogoText>Logo</LogoText> */}
    //       <img src="https://zerocodecourses.com/assets/theme/images/ZeroCodeCourse_logo_final.png" style={{width: '100%'}}/>
    //     </LogoContainer>
    //     <ContactInfo>
    //       <ContactItem>
    //         <img src={callIcon} />
    //         <div>
    //           <strong>Phone</strong>
    //           <div>(219) 555-0114</div>
    //         </div>
    //       </ContactItem>
    //       <ContactItem>
    //         <img src={emailIcon} />
    //         <div>
    //           <strong>Email</strong>
    //           <div>getpay@example.com</div>
    //         </div>
    //       </ContactItem>
    //       <ContactItem>
    //         <img src={locationIcon} />
    //         <div>
    //           <strong>Address</strong>
    //           <div>4517 Washington Ave. Manchester, Kentucky 39495</div>
    //         </div>
    //       </ContactItem>
    //     </ContactInfo>
    //   </FooterSection>
    //   <FooterSection>
    //     <h3>Company</h3>
    //     <FooterLinks>
    //       <FooterLink>Home</FooterLink>
    //       <FooterLink>About</FooterLink>
    //       <FooterLink>Blog</FooterLink>
    //       <FooterLink>Pages</FooterLink>
    //       <FooterLink>Contact</FooterLink>
    //     </FooterLinks>
    //   </FooterSection>
    //   <FooterSection>
    //     <h3>Help</h3>
    //     <FooterLinks>
    //       <FooterLink>Customer Support</FooterLink>
    //       <FooterLink>Terms & Conditions</FooterLink>
    //       <FooterLink>Privacy Policy</FooterLink>
    //     </FooterLinks>
    //   </FooterSection>
    //   <FooterSection>
    //     <SubscribeSection>
    //       <SubscribeTitle>Subscribe to our newsletter</SubscribeTitle>
    //       <SubscribeText>The latest insights, resources, expert opinions and company news.</SubscribeText>
    //       <SubscribeButton>Subscribe</SubscribeButton>
    //     </SubscribeSection>
    //   </FooterSection>
    // </FooterContainer>


    <AppFooterContainer>
      <AppFooterLogoContainer>
        <AppFooterLogo src='https://zerocodecourses.com/uploads/newhomeimg/footerlogo.png'></AppFooterLogo>
      </AppFooterLogoContainer>
      <AppFooterWrapper>
        <AppFooterDescriptionContainer>
          <AppFooterDescription>Democratizing ML and AI learning for aspiring Data scientists and educators.</AppFooterDescription>
          <GetStartButton>Get Started</GetStartButton>
        </AppFooterDescriptionContainer>
        <AppFooterInformationContainer>
          <ContactInfo>
            <ContactIcon src={callIcon}></ContactIcon>
            <Contact>+91 83106 23079</Contact>
          </ContactInfo>
          <MailInfo>
            <MailIcon src={emailIcon}></MailIcon>
            <Mail>info.zerocodelearning.com</Mail>
          </MailInfo>
          <DayTime>Weekdays (including Saturdays)</DayTime>
          <SupportContainer>
            <PhoneSupport>Phone Support - 9:30 am till 8:00 pm (India Time)</PhoneSupport>
            <EmailSupport>Email Support - 9:30 am till 8:00 pm</EmailSupport>
            <Holiday>Sundays & Public Holiday</Holiday>
          </SupportContainer>
        </AppFooterInformationContainer>
        <FooterLinks>
          <FooterLink>Home</FooterLink>
          <FooterLink>About</FooterLink>
          <FooterLink>Services</FooterLink>
          <FooterLink>Product</FooterLink>
          <FooterLink>Training</FooterLink>
          <FooterLink>Contact</FooterLink>
        </FooterLinks>
        <FooterLinks>
          <FooterLink>Terms & Condition</FooterLink>
          <FooterLink>Privacy Policy</FooterLink>
          <FooterLink>Careers</FooterLink>
          <FooterLink>Blogs</FooterLink>
          <FooterLink>Events</FooterLink>
        </FooterLinks>
      </AppFooterWrapper>
      <Hr></Hr>
      <ReservedContainer>
        <Reserved>© All rights reserved © 2024 Zero Code Courses</Reserved>
      </ReservedContainer>
    </AppFooterContainer>
  );
};

export default Footer;
