import styled from "styled-components";

export const ProfileContainer = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
`
export const ProfilePictureContainer = styled.div`
width: 100%;
display: flex;
align-items: flex-start;
`
export const ProfilePictureLable = styled.div`
font: Inter;
font-weight: 400;
font-size: 14px;
color: #24262D;
white-space: nowrap;
width: 200px;
`
export const ProfilePictureSelectInputContainer = styled.div`
width: 100%;
max-width: 685.34px;
height: 130.2px;
border-radius: 6px;
border: 1px solid #D5D6D7;
display: flex;
flex-direction: column;
gap: 10px;
align-items: center;
justify-content: center;
cursor: pointer;
`
export const UploadImg = styled.img`
height: 30px;
width: 30px;
`
export const UploadLable = styled.div`
font: Inter;
font-weight: 400;
font-size: 14px;
color: #000000;
`
export const ProfilePictureSelectInputWrapper = styled.div`
width: 100%;
max-width: 685.34px;
display: flex;
flex-direction: column;
gap: 10px;
/* align-items: flex-start; */
`
export const ProfilePicture = styled.img`
width: 96px;
height: 79px;
border-radius: 6px;
border: 1px solid #F4F5F7;
align-self: flex-start;
`
export const ProfileInput = styled.input`
display: none;
`
export const ProfilePictureWrapper = styled.div`
width: 100%;
max-width: 685.34px;
`
export const NameContainer = styled.div`
width: 100%;
display: flex;
align-items: flex-start;
/* gap: 30px; */
/* justify-content: space-between; */
`
export const NameLable = styled.div`
font: Inter;
font-weight: 400;
font-size: 14px;
color: #24262D;
width: 200px;
`
export const NameInputContainer = styled.div`
width: 100%;
max-width: 685.34px;
display: flex;
align-items: center;
gap: 20px;
`
export const Input = styled.input`
height: 48px;
border: 1px solid #E5E7EB;
border-radius: 6px;
background-color: #F4F5F7;
font: Inter;
font-weight: 400;
font-size: 14px;
color: #000000;
padding: 10px;
max-width: 685.34px;
width: 100%;
`
export const EmailContainer = styled.div`
display: flex;
align-items: flex-start;
/* gap: 30px; */
/* justify-content: space-between; */
`
export const EmailLable = styled.div`
font: Inter;
font-weight: 400;
font-size: 14px;
color: #24262D;
width: 200px;
`
export const EmailInputContainer = styled(NameInputContainer)`
`
export const NumberContainer = styled(EmailContainer)`
`
export const NumberLable = styled(EmailLable)`
`
export const NumberInputContainer = styled(EmailInputContainer)`
`
export const UpdateProfileButton = styled.div<{ isDisabled: boolean }>`
width: 138.32px;
height: 48px;
background: #3F87FE;
border-radius: 8px;
border: 8px;
font: Inter;
font-weight: 400;
font-size: 14px;
color: #FFFFFF;
margin-top: 20px;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
opacity: ${({ isDisabled }) => isDisabled ? 0.5 : 1};
`
export const Form = styled.form`
display: flex;
flex-direction: column;
gap: 20px;
`