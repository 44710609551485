import styled from "styled-components";

export const ChangePasswordContainer = styled.div`
`
export const OlaPasswordContainer = styled.div`
`
export const OldPassWordLable = styled.h4`
`
export const PasswordInput = styled.input`
height: 40px;
border-radius: 4px;
padding: 10px;
`
export const ChangePasswordTitle = styled.h4`
`
export const Form = styled.form`
display: flex;
flex-direction: column;
gap: 10px;
`