import React from 'react';
import { AppButtonContainer } from './AppButton.styled';
import { CircularProgress } from '@mui/material';

interface Props {
  title: string;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  loading?: boolean;
}

const AppButton: React.FC<Props> = ({ title, type, disabled, loading }) => {
  return (
    <AppButtonContainer type={type} disabled={disabled || loading}>
      {loading ? <CircularProgress size={16} sx={{color: '#ffffff'}} /> : title}
    </AppButtonContainer>
  );
}

export default AppButton;
