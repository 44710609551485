import React from 'react'
import { ContentProtectionContainer, ContentProtectionDescription, ContentProtectionImage, ContentProtectionLeftContainer, ContentProtectionLeftWrapper, ContentProtectionLi, ContentProtectionRightContainer, ContentProtectionRightWrapper, ContentProtectionTitle, ContentProtectionUl, ExamProctoringContainer, ExamProctoringDescription, ExamProctoringImage, ExamProctoringLeftContainer, ExamProctoringLeftWrapper, ExamProctoringLi, ExamProctoringRightContainer, ExamProctoringRightWrapper, ExamProctoringTitle, ExamProctoringUl, ExamSecurityContainer, ExamSecurityDescription, ExamSecurityImage, ExamSecurityLeftContainer, ExamSecurityRightContainer, ExamSecurityRightWrapper, ExamSecurityRightWrapperIcon, ExamSecuritySubDescription, ExamSecurityTitle, HomeContainer, OurMissionContainer, OurMissionDescription, OurMissionImage, OurMissionLeftContainer, OurMissionLeftWrapper, OurMissionRightContainer, OurMissionRightWrapper, OurMissionTitle, OurVisionContainer, OurVisionDescription, OurVisionImage, OurVisionLeftContainer, OurVisionLeftWrapper, OurVisionRightContainer, OurVisionRightWrapper, OurVisionTitle } from './Home.styled'
import AppRequestDemoButton from '../../components/AppRequestDemoButton/AppRequestDemoButton';
import ExamSecurityImg from '../../assets/Home/ExamSecurityImage.png';
import { ExamSecurityMaskGroupIcon } from '../../assets/Home/inde';
import OurMissionImg from '../../assets/Home/OurMissionImg.png';
import AppExploreButton from '../../components/AppExploreButton/AppExploreButton';
import ExamProctoringImg from '../../assets/Home/ExamProctoringImg.png';
import ProctoringExam1 from '../../assets/Home/ProcoterdExam1.png';
import ProctoringExam2 from '../../assets/Home/ProctoredExam2.png';
import Assessment from '../../assets/Home/Assesment.png';

const Home = () => {
  return (
    <HomeContainer>
      <ExamSecurityContainer>
        <ExamSecurityLeftContainer>
          <ExamSecurityTitle>AI  | ML | Business Analytics Proctored Assessment</ExamSecurityTitle>
          <ExamSecurityDescription>Online Proctoring Platform to Assess Grades and Certify, Validate Skills, Reliable and Scalable Access.</ExamSecurityDescription>
          {/* <AppRequestDemoButton title='Request a demo' /> */}
          {/* <ExamSecuritySubDescription>50,000+ people use Cequence to manage their contracts</ExamSecuritySubDescription> */}
        </ExamSecurityLeftContainer>
        <ExamSecurityRightContainer>
          {/* <ExamSecurityImage bgImg={ProctoringExam1}></ExamSecurityImage> */}
          <ExamSecurityImage src={ProctoringExam1}></ExamSecurityImage>

          {/* <ExamSecurityRightWrapper>
            <ExamSecurityRightWrapperIcon src={ExamSecurityMaskGroupIcon}></ExamSecurityRightWrapperIcon>
          </ExamSecurityRightWrapper> */}
        </ExamSecurityRightContainer>
      </ExamSecurityContainer>

      <OurMissionContainer>
        <OurMissionLeftContainer>
          <OurMissionLeftWrapper>
            <OurMissionImage src={ProctoringExam2}></OurMissionImage>
          </OurMissionLeftWrapper>
        </OurMissionLeftContainer>
        <OurMissionRightContainer>
          <OurMissionRightWrapper>
            <OurMissionTitle>Methodology</OurMissionTitle>
            {/* <OurMissionDescription>Features</OurMissionDescription> */}
            <ExamProctoringUl>
              <ExamProctoringLi>Access to objective and subjective questions</ExamProctoringLi>
              <ExamProctoringLi>Aligned with industry skill demand</ExamProctoringLi>
              <ExamProctoringLi>Analytics report</ExamProctoringLi>
            </ExamProctoringUl>
            {/* <AppExploreButton title='Explore Product' /> */}
          </OurMissionRightWrapper>
        </OurMissionRightContainer>
      </OurMissionContainer>

      <OurVisionContainer>
        <OurVisionLeftContainer>
          <OurVisionRightWrapper>
            <OurVisionTitle>Our Vision</OurVisionTitle>
            <OurVisionDescription>Enable industry and institutions to certify the right talent</OurVisionDescription>
            {/* <AppExploreButton title='Explore Product' /> */}
          </OurVisionRightWrapper>
        </OurVisionLeftContainer>
        <OurVisionRightContainer>
          <OurVisionLeftWrapper>
            <OurVisionImage src={Assessment}></OurVisionImage>
          </OurVisionLeftWrapper>
        </OurVisionRightContainer>
      </OurVisionContainer>

      <ExamProctoringContainer>
        <ExamProctoringLeftContainer>
          <ExamProctoringLeftWrapper>
            <ExamProctoringImage src={ProctoringExam1}></ExamProctoringImage>
          </ExamProctoringLeftWrapper>
        </ExamProctoringLeftContainer>
        <ExamProctoringRightContainer>
          <ExamProctoringRightWrapper>
            <ExamProctoringTitle>Exam Proctoring</ExamProctoringTitle>
            <ExamProctoringDescription>Remote monitoring platform with image id authentication for assessment.</ExamProctoringDescription>
            <ExamProctoringUl>
              <ExamProctoringLi>Video proctoring</ExamProctoringLi>
              <ExamProctoringLi>Live test</ExamProctoringLi>
              <ExamProctoringLi>Analytics on assessment</ExamProctoringLi>
            </ExamProctoringUl>
          </ExamProctoringRightWrapper>
        </ExamProctoringRightContainer>
      </ExamProctoringContainer>

      <ContentProtectionContainer>
        <ContentProtectionLeftContainer>
          <ContentProtectionRightWrapper>
            <ContentProtectionTitle>Security and Protection</ContentProtectionTitle>
            {/* <ContentProtectionDescription>My Proctor.ai Ensures Exam integrity by preventing internet navigation, use of mobile devices, screen captures, printing or sharing.</ContentProtectionDescription> */}
            <ContentProtectionUl>
              <ContentProtectionLi>Prevents internet navigation</ContentProtectionLi>
              <ContentProtectionLi>Prevents internet navigation</ContentProtectionLi>
              <ContentProtectionLi>Copy and download prevention</ContentProtectionLi>
            </ContentProtectionUl>
          </ContentProtectionRightWrapper>
        </ContentProtectionLeftContainer>
        <ContentProtectionRightContainer>
          <ContentProtectionLeftWrapper>
            <ContentProtectionImage src={ProctoringExam2}></ContentProtectionImage>
          </ContentProtectionLeftWrapper>
        </ContentProtectionRightContainer>
      </ContentProtectionContainer>
    </HomeContainer>
  )
}

export default Home