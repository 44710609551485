import React, { useRef, useState, useEffect } from 'react';

interface FullScreenWrapperProps {
  children: React.ReactNode;
  handleExitFullScreen: () => void;
  isFullScreen: boolean;
  setIsFullScreen: any;
}

const FullScreenWrapper: React.FC<FullScreenWrapperProps> = ({ children, handleExitFullScreen, isFullScreen, setIsFullScreen }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  // const [isFullScreen, setIsFullScreen] = useState(false);

  const handleEnterFullScreen = async () => {
    const element = containerRef.current;
    if (element) {
      try {
        if (element.requestFullscreen) {
          await element.requestFullscreen();
        } else if ((element as any).mozRequestFullScreen) {
          (element as any).mozRequestFullScreen();
        } else if ((element as any).webkitRequestFullscreen) {
          (element as any).webkitRequestFullscreen();
        } else if ((element as any).msRequestFullscreen) {
          (element as any).msRequestFullscreen();
        }
        setIsFullScreen(true);

        // Lock the ESC key (if supported)
        if ((navigator as any).keyboard && (navigator as any).keyboard.lock) {
          (navigator as any).keyboard.lock(["Escape"]);
        }

        // Store full-screen state
        localStorage.setItem('isFullScreen', 'true');
      } catch (error) {
        console.error("Error entering full-screen mode:", error);
      }
    }
  };

  // const handleExitFullScreen = () => {
  //   if (document.exitFullscreen) {
  //     document.exitFullscreen();
  //   } else if ((document as any).mozCancelFullScreen) {
  //     (document as any).mozCancelFullScreen();
  //   } else if ((document as any).webkitExitFullscreen) {
  //     (document as any).webkitExitFullscreen();
  //   } else if ((document as any).msExitFullscreen) {
  //     (document as any).msExitFullscreen();
  //   }
  //   setIsFullScreen(false);

  //   // Unlock the ESC key (if supported)
  //   if ((navigator as any).keyboard && (navigator as any).keyboard.unlock) {
  //     (navigator as any).keyboard.unlock();
  //   }

  //   // Clear full-screen state
  //   localStorage.removeItem('isFullScreen');
  // };

  const handleKeyDown = (event: KeyboardEvent) => {
    // Prevent the ESC key from exiting full-screen mode
    if (event.key === 'Escape') {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  useEffect(() => {
    const handleFullScreenChange = () => {
      if (!document.fullscreenElement) {
        setIsFullScreen(false);

        // Unlock the ESC key when exiting full-screen
        if ((navigator as any).keyboard && (navigator as any).keyboard.unlock) {
          (navigator as any).keyboard.unlock();
        }

        // Clear full-screen state
        localStorage.removeItem('isFullScreen');
      }
    };

    // Add event listener for keydown events
    document.addEventListener('keydown', handleKeyDown);

    // Check if full-screen mode should be entered
    const storedState = localStorage.getItem('isFullScreen');
    if (storedState === 'true') {
      handleEnterFullScreen();
    } else {
      handleEnterFullScreen();
    }

    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        position: isFullScreen ? 'fixed' : 'relative',
        top: 0,
        left: 0,
        width: isFullScreen ? '100%' : 'auto',
        height: isFullScreen ? '100%' : 'auto',
        zIndex: isFullScreen ? 1000 : 'auto',
        backgroundColor: isFullScreen ? 'white' : 'transparent',
        overflow: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {/* {isFullScreen && (
        <button
          onClick={handleExitFullScreen}
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            zIndex: 1001,
            padding: '10px',
            backgroundColor: 'rgba(0,0,0,0.5)',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          Exit Full Screen
        </button>
      )} */}
      {children}
    </div>
  );
};

export default FullScreenWrapper;
