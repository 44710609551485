import React, { useEffect, useRef, useState } from 'react'
import { ICandidate } from '../../types/candidate';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import { deleteSingleImage, uploadSingleImage } from '../../utils/helpers/global';
import { serverUpdateCandidata } from '../../services/serverApi';

const useProfile = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [adminData, setAdminData] = useState<ICandidate>();
    const [loading, setLoading] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleSelectFile = () => {
        fileInputRef.current?.click();
    };

    const schema = Yup.object().shape({
        firstName: Yup.string().required("Please enter a first name.*"),
        lastName: Yup.string().required("Please enter a last name.*"),
        middleName: Yup.string().required("Please enter a middle name.*"),
        email: Yup.string().required("Please enter a email.*"),
        number: Yup.string().required("Please enter a mobile number.*")
    });

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileData = event.target.files?.[0];
        if (fileData) {
            setFile(fileData)
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setAdminData(prevState => ({...prevState, [name]: value }));
    }

    const handleSubmit = async(data?: ICandidate) => {
        try {
            setLoading(true);
            if (file) {
                const imagesUrl = await uploadSingleImage(file);
                
                if (data && data.profileImg) {
                    await deleteSingleImage(data.profileImg);
                }
                
                if (data) {
                    data.profileImg = imagesUrl;
                }
            }
            if (data) {
                await serverUpdateCandidata(data);
            }
            localStorage.removeItem('candidate')
            localStorage.setItem('candidate', JSON.stringify(data));
            navigate('/profile')
            setLoading(false);
        } catch (e) {
            setLoading(false);
            console.error(e);
        }
    }

    useEffect(() => {
        const adminDataString = localStorage.getItem('candidate');
        if (adminDataString) {
          const adminData = JSON.parse(adminDataString);
          setAdminData(adminData)
        } else {
          setAdminData(undefined)
        }
    }, [])

    return {
        fileInputRef,
        handleSelectFile,
        handleFileChange,
        adminData,
        handleInputChange,
        file,
        schema,
        handleSubmit,
        loading
    }
}

export default useProfile