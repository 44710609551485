import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { ExamStatusType, IExamQuestion } from '../../types/exam.d';
import Webcam from 'react-webcam';
import { ICandidate } from '../../types/candidate';
import { serverGetExamQuestion, serverInsertExamComplete, serverUpdateExamScheduleStatus } from '../../services/serverApi';

const useExamModule = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const routeData = location.state;

    if (!routeData) {
        navigate(-1);
    }

    const [loading, setLoading] = useState<boolean>(false);
    const [examQuestionData, setExamQuestionData] = useState<IExamQuestion[]>([]);
    const webcamRef = useRef<Webcam>(null);
    const [adminData, setAdminData] = useState<ICandidate>();
    const [currentQuestion, setCurrentQuestion] = useState<number>(1);
    const [openExamCompleteDialog, setOpenExamCompleteDialog] = useState<boolean>(false);
    const [isFullScreen, setIsFullScreen] = useState(false);


    const handleExitFullScreen = () => {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if ((document as any).mozCancelFullScreen) {
        (document as any).mozCancelFullScreen();
      } else if ((document as any).webkitExitFullscreen) {
        (document as any).webkitExitFullscreen();
      } else if ((document as any).msExitFullscreen) {
        (document as any).msExitFullscreen();
      }
      setIsFullScreen(false);

      // Unlock the ESC key (if supported)
      if ((navigator as any).keyboard && (navigator as any).keyboard.unlock) {
        (navigator as any).keyboard.unlock();
      }

      // Clear full-screen state
      localStorage.removeItem('isFullScreen');
    };

    const getMark = () => {
      const markData = examQuestionData?.map((item: any) => {
        if (item?.value) {
          const findOptionData = item?.options?.find((option: any) => option?.name === item?.value)
          if (findOptionData?.answer) {
            return item?.mark
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      })
  
      const sum = markData.reduce((accumulator: any, currentValue: any) => accumulator + currentValue, 0);
  
      return sum;
    }

    const handleSubmit = async () => {
      try {
        setLoading(true);
        const data = {
          examId: routeData?.examData?._id,
          examScheduleId: routeData?._id,
          mark: Number(getMark())
        }
        await serverInsertExamComplete(data);
        const status = Number(getMark()) > routeData?.examData?.passingMark ? ExamStatusType?.Passed : ExamStatusType?.Failed;
        await serverUpdateExamScheduleStatus(routeData?._id, status)
      } catch (err) {
        console.log(err);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }

    const handleOpenExamCompleteDialog = () => {
      setOpenExamCompleteDialog(true);
      handleExitFullScreen()
      handleSubmit()
    }

    const handleCloseExamCompleteDialog = () => {
      setOpenExamCompleteDialog(false);
      navigate('/exams');
    }

    const handleNextQuestion = () => {
      setCurrentQuestion(currentQuestion + 1);
    }

    const handlePreviousQuestion = () => {
      setCurrentQuestion(currentQuestion - 1);
    }

    const getExamQuestionData = async () => {
      try {
        setLoading(true);
        const data = await serverGetExamQuestion(
          routeData?.examData?.examTypeId,
          routeData?.examData?.questionTypeId,
          routeData?.examData?.subjectId,
          routeData?.examData?.topicId
        );
        const questionData = data?.data;
        const finalData = routeData?.examData?.questions?.map((item: any, index: number) => {
          const findData = questionData?.find((question: any) => question?._id === item?.questionId);
          return {
            ...findData,
            index: index + 1,
            value: null
          }
        })
        setExamQuestionData(finalData);
      } catch (err) {
        console.error(err);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    const handleSelectAnswer = (item: any, e: any) => {
      const finalData = examQuestionData?.map((data: any) => {
        return {
          ...data,
          value: item?.index === data?.index ? e?.target?.value : data?.value
        }
      })
      setExamQuestionData(finalData);
    }
    
    useEffect(() => {
      const adminDataString = localStorage.getItem('candidate');
      if (adminDataString) {
        try {
          const adminData = JSON.parse(adminDataString);
          setAdminData(adminData);
        } catch (error) {
          console.error("Error parsing admin data:", error);
        }
      } else {
        setAdminData(undefined);
      }
    }, []);

    useEffect(() => {
      getExamQuestionData();
    }, []);

    return {
      adminData,
      routeData,
      examQuestionData,
      currentQuestion,
      webcamRef,
      handleNextQuestion,
      handlePreviousQuestion,
      handleSelectAnswer,
      setCurrentQuestion,
      handleOpenExamCompleteDialog,
      handleCloseExamCompleteDialog,
      openExamCompleteDialog,
      handleSubmit,
      isFullScreen,
      handleExitFullScreen,
      setIsFullScreen,
      setOpenExamCompleteDialog
    }
}

export default useExamModule;
