import styled from "styled-components";

export const ProfileDashboardContainer = styled.div`
display: flex;
flex-direction: column;
gap: 60px;
`
export const ProfileDashboardWrapper = styled.div`
display: flex;
align-items: center;
gap: 40px;
`
export const ProfileCardContainer = styled.div`
height: 207px;
width: 417px;
background: #FFFFFF;
box-shadow: 0px 4px 4px 0px #00000040;
padding: 20px;
display: flex;
flex-direction: column;
gap: 20px;
`
export const ProfileCardHeaderContainer = styled.div`
height: 70px;
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
`
export const ProfileImg = styled.img`
width: 66.28px;
height: 70px;
border-radius: 128px;
`
export const ProfileName = styled.div`
font: Inter;
font-weight: 400;
font-size: 25px;
color: #000000;
`
export const ProfileEditButton = styled.button`
width: 75px;
height: 38px;
background: #0575E6;
border-radius: 8px;
border: none;
font: Inter;
font-weight: 400;
font-size: 16px;
color: #FFFFFF;
cursor: pointer;
`
export const ProfileCardHeaderWrapper = styled.div`
display: flex;
align-items: center;
gap: 20px;
`
export const ProfileDescription = styled.div`
font: Inter;
font-weight: 400;
font-size: 16px;
color: #A1A1A1;
`
export const DashboardSummaryContainer = styled.div`
display: grid;
grid-template-columns: repeat(2, 1fr);
gap: 20px;
grid-auto-rows: minmax(80px, auto);
`
export const SummaryBoxContainer = styled.div<{ bgColor: string }>`
width: 226px;
height: 90px;
background: ${({ bgColor }) => bgColor};
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`
export const SummaryBoxTitle = styled.div`
font: Inter;
font-weight: 400;
font-size: 16px;
color: #FFFFFF;
`
export const SummaryBoxCount = styled.div`
font: Inter;
font-weight: 400;
font-size: 33px;
color: #FFFFFF;
`
export const StartExamButton = styled.div`
width: 91px;
height: 25px;
border-radius: 90px;
background-color: #3F87FE;
font: Inter;
font-weight: 400;
font-size: 12px;
color: #FFFFFF;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
`
export const TableContainer = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
`
export const TableTitle = styled.div`
font: Inter;
font-weight: 400;
font-size: 18px;
color: #24262D;
`
export const ExamStatus = styled.div<{bgColor: string, color: string}>`
padding: 5px 15px 5px 15px;
border-radius: 24px;
background-color: ${({ bgColor }) => bgColor};
color: ${({ color }) => color};
`