// import React, { useState, useRef, useEffect } from 'react';
// import { io, Socket } from 'socket.io-client';

// const Student: React.FC = () => {
//   const [stream, setStream] = useState<MediaStream | null>(null);
//   const videoRef = useRef<HTMLVideoElement | null>(null);
//   const socketRef = useRef<Socket | null>(null);

//   useEffect(() => {
//     socketRef.current = io('http://localhost:3010');
//     console.log('Connected to server');

//     return () => {
//       socketRef.current?.disconnect();
//     };
//   }, []);

//   const startScreenRecording = async () => {
//     try {
//       const displayStream = await navigator.mediaDevices.getDisplayMedia({
//         video: true
//       });
//       console.log('Captured screen stream:', displayStream);
//       setStream(displayStream);
//       if (videoRef.current) {
//         videoRef.current.srcObject = displayStream;
//         videoRef.current.play();
//       }

//       const mediaRecorder = new MediaRecorder(displayStream, {
//         mimeType: 'video/webm',
//       });

//       mediaRecorder.ondataavailable = (event) => {
//         if (event.data.size > 0) {
//           socketRef.current?.emit('stream', { data: event.data });
//         }
//       };

//       mediaRecorder.start(1000); // Send data every 1 second

//     } catch (error) {
//       console.error('Error: ', error);
//     }
//   };

//   return (
//     <div>
//       <h2>Student Screen Recorder</h2>
//       <video ref={videoRef} style={{ width: '80%', border: '1px solid black' }} autoPlay></video>
//       <button onClick={startScreenRecording}>Start Screen Recording</button>
//     </div>
//   );
// };

// export default Student;



import React, { useRef, useEffect } from 'react';
import Webcam from 'react-webcam';
import io from 'socket.io-client';

// const serverUrl = "http://localhost:3010";
// const serverUrl = 'https://exam-backend-theta.vercel.app';
const serverUrl = 'https://backend.zerocodecourses.com';
const socket = io(serverUrl);
// const socket = io(serverUrl, {
//   query: { token:"blablabla...." },
//   path: '/socket.io',
//   withCredentials: true,
//   transports: ['websocket', 'polling'],
//   secure: true,
// })


// // const io = require("socket.io-client");
// const socket = io("https://server-domain.com", {
//   withCredentials: true
// });

const WebcamStream: React.FC = () => {
  const webcamRef = useRef<Webcam>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (webcamRef.current && canvasRef.current) {
        const video = webcamRef.current.video;
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        if (video?.readyState === 4 && context) {
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          context.drawImage(video, 0, 0, canvas.width, canvas.height);

          const imageData = canvas.toDataURL('image/webp');
          socket.emit('stream', imageData);
        }
      }
    }, 100); // Stream every 100ms

    return () => clearInterval(interval);
  }, []); // Empty dependency array to run only once

  return (
    <div>
      <Webcam ref={webcamRef} style={{ width: '100%' }} />
      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </div>
  );
};

const Student: React.FC = () => {
  return (
    <div>
      {/* <h2>Student Webcam Stream</h2> */}
      <WebcamStream />
    </div>
  );
};

export default Student;







