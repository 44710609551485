import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { ExamCompletedDialogBodyContainer, ExamCompletedDialogBodyTitle, ExamCompletedDialogContainer, ExamCompletedDialogDescription, ExamCompletedDialogHeader, ExamCompletedDialogHeaderContainer, ExamCompletedMarkContainer, ExamCompletedPassingMark, ExamCompletedTotalMark } from './ExamCompletedDialog.styled';

interface Props {
  open: boolean;
  handleClose: () => void;
  examScheduleData: any;
  examQuestionData: any;
}

const ExamCompletedDialog: React.FC<Props> = ({ open, handleClose, examScheduleData, examQuestionData }) => {
  const getMark = () => {
    const markData = examQuestionData?.map((item: any) => {
      if (item?.value) {
        const findOptionData = item?.options?.find((option: any) => option?.name === item?.value)
        if (findOptionData?.answer) {
          return item?.mark
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    })

    const sum = markData.reduce((accumulator: any, currentValue: any) => accumulator + currentValue, 0);

    return sum;
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title" sx={{ background: '#4CAF50', color: '#FFFFFF' }}>Exam Completed</DialogTitle>
      <DialogContent dividers={true}>
        <ExamCompletedDialogContainer>
            <ExamCompletedDialogHeaderContainer>
                <ExamCompletedDialogHeader>Congratulation!</ExamCompletedDialogHeader>
                <ExamCompletedDialogDescription>You have successfully completed the exam</ExamCompletedDialogDescription>
            </ExamCompletedDialogHeaderContainer>
            <ExamCompletedDialogBodyContainer>
                <ExamCompletedDialogBodyTitle>Marks</ExamCompletedDialogBodyTitle>
                <ExamCompletedMarkContainer>
                    <ExamCompletedPassingMark>{getMark()}</ExamCompletedPassingMark>
                    <ExamCompletedTotalMark>/{examScheduleData?.examData?.totalMark}</ExamCompletedTotalMark>
                </ExamCompletedMarkContainer>
            </ExamCompletedDialogBodyContainer>
        </ExamCompletedDialogContainer>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} color='inherit'>Close</Button>
        <Button variant='outlined' onClick={handleClose} color='inherit'>Okay</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ExamCompletedDialog