// src/Header.js
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Avatar, Box, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { AccountEmail, AccountName, AccountNameContainer, AuthLinks, FreeTrialButton, HeaderContainer, HeaderWrapper, Logo, NavLinks } from './AppHeader.styled';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ICandidate } from '../../types/candidate';
import RequestDemoDialog from '../../dialogs/RequestDemoDialog/RequestDemoDialog';
import useAppHeader from './useAppHeader';

const AppHeader = () => {
  const {
    openRequestDemoDialog,
    handleOpenRequestDemoDialog,
    handleCloseRequestDemoDialog
  } = useAppHeader();
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [adminData, setAdminData] = useState<ICandidate>();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('candidate')
    navigate('/')
    window.location.reload();
  }

  const handleNavigate = (path: string) => {
    localStorage.setItem('isActive', JSON.stringify(true));
    navigate(path)
    window.location.reload();
  }
  const handleLogoClick = () => {
    // navigate('/')
    window.location.href = 'https://zerocodecourses.com/';
  }

  useEffect(() => {
    const adminDataString = localStorage.getItem('candidate');
    if (adminDataString) {
      const adminData = JSON.parse(adminDataString);
      setAdminData(adminData)
    } else {
      setAdminData(undefined)
    }
  }, [location.pathname])
  return (
    <HeaderContainer>
      <HeaderWrapper>
        <Logo style={{cursor: 'pointer'}} onClick={() => handleLogoClick()}>
          <img src="https://zerocodecourses.com/assets/theme/images/ZeroCodeCourse_logo_final.png" />
            {/* Logo */}
        </Logo>
        <NavLinks>
          {/* <a style={{cursor: 'pointer'}} onClick={() => navigate('/home/faqs')}>Faqs</a>
          <a style={{cursor: 'pointer'}} onClick={() => navigate('/home/contact')}>Contact Us</a> */}
        </NavLinks>
      </HeaderWrapper>
      <AuthLinks>
        {adminData ? (
          <>
            <NotificationsIcon sx={{color: '#898989'}} />
            <React.Fragment>
              <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', gap: '10px' }}>
                <Tooltip title="Account settings">
                  <IconButton
                    // onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                  >
                    {/* <Avatar sx={{ width: 40, height: 40 }}>M</Avatar> */}
                    <Avatar src={adminData?.profileImg} />

                  </IconButton>
                </Tooltip>
                <AccountNameContainer>
                  <AccountName>Profile</AccountName>
                  <AccountEmail>Hello, {adminData?.firstName}</AccountEmail>
                </AccountNameContainer>
                <IconButton onClick={handleClick}>
                  <KeyboardArrowDownIcon />
                </IconButton>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&::before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem onClick={() => handleNavigate('/profile')} >
                  Profile
                </MenuItem>
                <MenuItem onClick={() => handleNavigate('/exams')}>
                  My Exam
                </MenuItem>
                <MenuItem onClick={() => handleNavigate('/change-password')}>
                  Change Password
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  Logout
                </MenuItem>
              </Menu>
            </React.Fragment>
          </>
        ) : (
          <React.Fragment>
            {(location.pathname !== '/Login' && location.pathname !== '/login') && (
              <a style={{cursor: 'pointer'}} onClick={() => navigate('/Login')}>Login</a>
            )}
            <FreeTrialButton onClick={() => handleOpenRequestDemoDialog()}>Request a Demo</FreeTrialButton>
          </React.Fragment>
        )}
      </AuthLinks>

      {openRequestDemoDialog && (
        <RequestDemoDialog open={openRequestDemoDialog} handleClose={handleCloseRequestDemoDialog} />
      )}
    </HeaderContainer>
  );
};

export default AppHeader;
